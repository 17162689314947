import React, { Component } from 'react'
import './style.css'
import Select from 'react-select'
import { Link } from 'react-router-dom';
import arrow from '../../Images/CandidateSignup/arrow.png'
import CandidateImg1 from '../../Images/CandidateSignup/Candidate Signup1.jpg'

export default class CandidateStep1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
          name: 'React',
          selectedOption: {},
          selectedOption2: {},
          onSubmit:false,
          selectedOptions: {}
        };
        console.log(this.state.onSubmit)
      }
      handleChange1 = (selectedOption) => {
        this.setState({selectedOption});
        if(this.state.selectedOption)
        {
          this.setState({onSubmit: false});
          console.log(this.state.onSubmit)
        }
      };
    
      handleChange2 = (selectedOption) => {
        this.setState({selectedOption2: selectedOption});
        // if(this.state.selectedOption2.length === 4){
        //   this.state.onSubmit(true);
        // }
        // else
        // {
        //   this.state.onSubmit(false);
        // }
      }
  render() {
    const options1 = [
        {value: 'australia', label: 'Australia'},
        {value: 'austria', label: 'Austria'}
      ];
  
      const options2 = [
          {value: 'Aberdare', label: 'Aberdare', link: 'australia'},
          {value: 'Adelaide', label: 'Adelaide', link: 'australia'},
          {value: 'Albany', label: 'Albany', link: 'australia'},
          {value: 'Albury', label: 'Albury', link: 'australia'},
          {value: 'Aldgate', label: 'Aldgate', link: 'australia'},
          {value: 'Alexandra', label: 'Alexandra', link: 'australia'},
          {value: 'Alstonville', label: 'Alstonville', link: 'australia'},
          {value: 'Andergrove', label: 'Andergrove', link: 'australia'},
          {value: 'Anna Bay', label: 'Anna Bay', link: 'australia'},
          {value: 'Appin', label: 'Appin', link: 'australia'},
          {value: 'Ararat', label: 'Ararat', link: 'australia'},
          {value: 'Armidale', label: 'Armidale', link: 'australia'},
          {value: 'Atherton', label: 'Atherton', link: 'australia'},
          {value: 'Australind', label: 'Australind', link: 'australia'},
          {value: 'Ayr', label: 'Ayr', link: 'australia'},
          {value: 'Bacchus-Marsh', label: 'Bacchus Marsh', link: 'australia'},
          {value: 'Bairnsdale', label: 'Bairnsdale', link: 'australia'},
          {value: 'Ballan', label: 'Ballan', link: 'australia'},
          {value: 'Ballarat', label: 'Ballarat', link: 'australia'},
          {value: 'Ballina', label: 'Ballina', link: 'australia'},
          {value: 'Bannockburn', label: 'Bannockburn', link: 'australia'},
          {value: 'Banora-Point', label: 'Banora Point', link: 'australia'},
          {value: 'Bargara', label: 'Bargara', link: 'australia'},
          {value: 'Bargo', label: 'Bargo', link: 'australia'},
          {value: 'Barwon-Heads', label: 'Barwon Heads', link: 'australia'},
          {value: 'Batemans-Bay', label: 'Batemans Bay', link: 'australia'},
          {value: 'Bathurst', label: 'Bathurst', link: 'australia'},
          {value: 'Bay-View', label: 'Bay View', link: 'australia'},
          {value: 'Beachmere', label: 'Beachmere', link: 'australia'},
        //   Austria Cities 
          {value: 'Absam', label: 'Absam', link: 'austria'},
          {value: 'Abtenua', label: 'Abtenua', link: 'austria'},
          {value: 'Achenkirch', label: 'Achenkirch', link: 'austria'},
          {value: 'Admont', label: 'Admont', link: 'austria'},
          {value: 'Adnet', label: 'Adnet', link: 'austria'},
          {value: 'Aigen-im-Ennstal', label: 'Aigen im Ennstal', link: 'austria'},
          {value: 'Alberndorf-in-der-Riedmark', label: 'Alberndorf in der Riedmark', link: 'austria'},
          {value: 'Aldrans', label: 'Aldrans', link: 'austria'},
          {value: 'Alkoven', label: 'Alkoven', link: 'austria'},
          {value: 'Alland', label: 'Alland', link: 'austria'},
          {value: 'Alpbach', label: 'Alpbach', link: 'austria'},
          {value: 'Altach', label: 'Altach', link: 'austria'},
          {value: 'Altenberg-bei-Linz', label: 'Altenberg bei Linz', link: 'austria'},
        ];
  
      const filteredOptions = options2.filter((o) => o.link === this.state.selectedOption.value)
const Styles = {
        control: (base, state) => ({
          ...base,
          '&:hover': { borderColor: '#B7C93B' }, 
            border: '1px solid #B7C93B',
            borderRadius: '10px',
            boxShadow: '0px 15px 43px rgba(160,168,189,0.3)',
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...base,
            backgroundColor: isFocused ? "#B7C93B" : "white",
            background: isSelected ? "#B7C93B" : "white",
            '&:hover': { backgroundColor: isSelected ? "#B7C93B": '#B7C93B', 
            color: isSelected ? "#fff": "#fff", opacity: isSelected? '1': '0.7' },
            Transition: "all 0.5s ease"
          };
    },
        menuList: (base) => ({
            ...base,
            '&:hover': { borderColor: 'lightgray' }
          }),
          multiValue: (styles, {data}) =>{
            return {
              ...styles,
              backgroundColor: "#B7C93B",
              color: '#fff',
              fontFamily: `'Red Hat Text', sans-serif`
            }
          },
          multiValueLabel: (styles, {data}) =>{
            return {
              ...styles,
              color: '#fff',
              // fontWeight: '500'
            }
          },
          multiValueRemove: (styles, {data}) =>{
            return {
              ...styles,
              color: '#fff',
              ':hover':{
                color: "#fff",
                backgroundColor: "#B7C93B",
              }
            }
          }
      }; 
    
    return (
       
      <div className='Signup my-5 py-5'>
        <div className="container-md py-5">
            <div className="row">
                <div className="col-lg-5 col-md-6 px-md-2 px-4 image-col">
                    <div className="Signup__left h-100 d-flex justify-content-md-end justify-content-center align-items-lg-start align-items-center">
                        <img src={CandidateImg1} alt="" className='Signup__left--img'/>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6">
                    <div className="Signup__right h-100 pt-2 pb-5  px-lg-4 px-md-0 px-4">
                        <h1 className="Signup__right__heading text-md-start text-sm-center text-start">Start using rndmatch</h1>
                        <p className='Signup__right__para text-md-start text-sm-center text-start'>No long signups. 5 simple questions and you’re in</p>    
                        <div className="row">
                      <h5 className="Signup__right__heading2 mt-lg-5 mt-md-4 mt-5">
                        Where do you want to work?
                      </h5>
                      <div className="col-lg-5 col-md-12 col-sm-6 col-12">
                        <Select
                          name="form-field-name"
                          onChange={this.handleChange1}
                          options={options1}
                          styles={Styles}
                          placeholder="Select country"
                          className="mb-3 Signup__right--select"
                        />
                      </div>
                      <div className="col-lg-7 col-md-12 col-sm-6 col-12">
                        <Select
                          isMulti
                          name="form-field-name"
                          value={this.state.selectedOption2.value}
                          onChange={this.handleChange2}
                          options={filteredOptions}
                          styles={Styles}
                          placeholder="Select city"
                          className="mb-3 Signup__right--select"
                          isOptionDisabled={()=>this.state.selectedOption2.length >= 5}
                        />
                      </div>
                      <p className="ms-1 Signup__right__detail">
                        Choose country and select up to five cities
                      </p>
                      
                    </div>
                    <div className="d-flex justify-content-start mt-2 mb-5">
                      {this.state.selectedOption2.length ===1 && <button className="Signup__right--button my-3 py-2 px-3" >
                      <Link to={`/candidate_step2`} className="Signup__right--button--Link d-flex align-items-center text-decoration-none">
                                Next 
                                <img src={arrow} alt="" className='Signup__right--button--img ms-2'/>
                            </Link>
                      </button>}
                    </div>
                        <p className="Signup__right__bottom">Already Have an account ? <Link to={`/login`} className="text-decoration-none Signup__right__bottom--Link">Log in</Link> </p>
                    </div>
                </div>
            </div>
           </div>
           </div> 
                    
    );
  }
}
