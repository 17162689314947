import React from 'react'
import './style.css'
import Select from 'react-select'
import { Link } from 'react-router-dom'

const employer = [
    { value: 'Candidate', label: 'Candidate' },
    { value: 'Employer', label: 'Employer' },
  ]  
  const Styles = {
    control: (base, state) => ({
      ...base,
      '&:hover': { borderColor: '#B7C93B' }, 
        border: '0px solid #B7C93B',
        borderRadius: '10px',
        boxShadow: '0px 0px 0px rgba(160,168,189,0.3)',
        backgroundColor: '#fffff',
        fontFamily: 'Red Hat Text',
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#B7C93B" : "white",
        background: isSelected ? "#B7C93B" : "white",
        '&:hover': { backgroundColor: isSelected ? "#B7C93B": '#B7C93B', color: isSelected ? "#fff": "#fff", opacity: isSelected? '1': '0.7' },
        Transition: "all 0.5s ease"
      };
},
    menuList: (base) => ({
        ...base,
        '&:hover': { borderColor: 'lightgray' }
      }),
      multiValue: (styles, {data}) =>{
        return {
          ...styles,
          backgroundColor: "#B7C93B",
          color: '#fff',
          fontFamily: `'Red Hat Text', sans-serif`
        }
      },
      multiValueLabel: (styles, {data}) =>{
        return {
          ...styles,
          color: '#fff',
          // fontWeight: '500'
        }
      },
      multiValueRemove: (styles, {data}) =>{
        return {
          ...styles,
          color: '#fff',
          ':hover':{
            color: "#fff",
            backgroundColor: "#FFFFFF",
          }
        }
      }
  };

function Form() {
  return (
    <div className='Form pt-5'>
        <div className="container-md mt-5 px-md-0 pt-2">
        <h1 className="Form__heading text-center mt-5">
        Find perfect candidates 
            </h1>
            <p className='Form__detail text-center'><span className='d-sm-block d-none'>Hire top postdocs, research scientists, proteomicists, <br /></span ><span className='d-sm-none d-inline'>Hire top postdocs, research scientists, proteomicists,</span> R&D managers, bioinformaticians and more</p>
            <form action="/employer" className='Form--form mx-auto'>
            <div className="row px-sm-5 px-3 ">
                    <div className="col-sm-6">
                        <input type="text" className='form-control form-control-lg Contact--input me-sm-3' placeholder='Full Name *'/>
                    </div>
                    <div className="col-sm-6">
                    <Select options={employer} styles={Styles} placeholder="Select position *" className='ms-sm-3 form-control px-0 form-control-lg Contact--select '/>
                    </div>
                </div>
                <div className="row px-sm-5 px-3">
                    <div className="col-sm-6">
                        <input type="email" className='form-control form-control-lg Contact--input me-sm-3' placeholder='Work email *'/>
                    </div>
                    <div className="col-sm-6">
                        <input type="number" className='form-control form-control-lg Contact--input ms-sm-3' placeholder='Phone Number *'/>
                    </div>
                </div>
                <div className="row px-sm-5 px-3">
                    <div className="col-sm-6">
                        <input type="text" className='form-control form-control-lg Contact--input me-sm-3' placeholder='Job title *'/>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" className='form-control form-control-lg Contact--input ms-sm-3' placeholder='Company name *'/>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                    <button className='Form--form--button mx-auto'><Link to={`/employer`} className="text-decoration-none Banner__left--button--Link">See active candidates</Link></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
  )
}

export default Form