import React from 'react'
import { Link } from 'react-router-dom'

function Cookies() {
  return (
    <div className='Policy d-flex justify-content-center px-4'>
            <div className="Policy__container pb-5 pt-4">
                <h6 className="Policy__container--h6 text-uppercase">
                OUR USE OF COOKIES
                </h6>
                <p className='Policy__container__para'>
                This policy explains what cookies are and how we use them. It also explains the different types of cookies we use, and how you can change or delete them.
                </p>
                <p className='Policy__container__para'>We hope that this policy helps you understand, and feel more confident about, our use of cookies. If you have any further queries, please contact us at support@rndmatch.com.</p>
                <p className='Policy__container__para'>For information on how your information is collected and used by our site generally, please see our Privacy Policy, which is available <Link to={'/privacy'} className='Policy__container__para--Link'>here</Link>.</p>
                <h6 className="Policy__container--h6 text-uppercase">
                WHY DO WE USE COOKIES?
                </h6>
                <p className='Policy__container__para'>We use cookies to distinguish you from other users of our site. We do this to provide you with a good user experience and to improve our site. By continuing to use our site, you are agreeing to our use of cookies.</p>
                <h6 className="Policy__container--h6 text-uppercase">
                WHICH COOKIES DO WE USE?
                </h6>
                <p className='Policy__container__para'>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree by continuing to use our site.</p>
                <p className='Policy__container__para'>Cookies contain information that is transferred to your computer's hard drive.</p>
                <p className='Policy__container__para'>We use:</p>
                <ul className='Policy__container__list'>
                    <li className="Policy__container__list__item mb-2">Strictly necessary cookies. These are essential for the operation of our site. They include, for example, cookies that enable you to move around the site and use its features. Without these cookies, the site cannot provide services such as remembering your login details.</li>
                    <li className="Policy__container__list__item mb-2"><p className='Policy__container__para'>Google Analytics (performance cookies). We use Google Analytics, which is a web analytics service provided by Google Inc. This service helps us to analyse how users our site by collecting information such as how often users visit our site, what pages they visit, and what other sites they used prior to coming to the site. We use this information to improve the way our site works (for example, by ensuring that users are finding what they are looking for easily) and to enhance your experience generally.</p> <p className='Policy__container__para'>The information generated by the cookie about your use of our site is transmitted to and stored by Google on their servers. Google will use this information to evaluate your use of our site, compile reports on website activity, and provide other services relating to website activity. Google’s ability to use and share information collected by Google Analytics about your visits to the site is restricted by the Google Analytics Terms of Use and the Google Privacy Policy.</p></li>
                    <li className="Policy__container__list__item mb-2">Social Media Cookies. These allow us to share what you are doing on social media sites like Twitter and Facebook. We do not have control over these cookies. Please refer to the privacy policies of the social media sites for how their cookies work.</li>
                </ul>
                <p className='Policy__container__para'>If you want to learn more about cookies you can do so at www.allaboutcookies.org</p>
                <h6 className="Policy__container--h6 text-uppercase">
                WHAT ABOUT THIRD PARTY COOKIES?
                </h6>
                <p className='Policy__container__para'>Third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies (described above) or targeting cookies. Targeting cookies collect information about your browsing habits. This makes it possible to make advertising more relevant to you and your interests. Targeting cookies also limit the number of times you see an advert. They also remember the sites you visit and share that information with other parties such as advertisers.</p>
                <h6 className="Policy__container--h6 text-uppercase">
                DON’T FANCY A COOKIE?
                </h6>
                <p className='Policy__container__para'>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.</p>
                <h6 className="Policy__container--h6 text-uppercase">
                CHANGES TO THE COOKIES POLICY
                </h6>
                <p className='Policy__container__para'>Any changes we may make to our Cookies Policy in the future will be posted on this page.</p>
                <p className='Policy__container__para'>© rndmatch Limited. All Rights Reserved</p>
        </div>
    </div>
  )
}

export default Cookies