import React from 'react'
import './style.css'
import Team1 from '../../../Images/About/Team1.png'
import Team2 from '../../../Images/About/Team2.png'
import Team3 from '../../../Images/About/Team3.png'

const card=[
    {
        id: 1,
        img: Team1,
        name: 'Adeniyi Fasoro, PhD',
        position: 'Chief Executive Officer'
    },
    {
        id: 2,
        img: Team2,
        name: 'Gaurav Dutta',
        position: 'Chief Strategy Officer'
    },
    {
        id: 3,
        img: Team3,
        name: 'Dapo Satiregun',
        position: 'Chief Operating Officer'
    },
]

function Team() {
  return (
    <div className='Team pb-5'>
        <div className="container-md px-md-0 px-sm-5 px-4">
            <p className="Team__heading pb-sm-4">
            The team that makes the difference
            </p>
            <div className="row px-lg-5 pb-md-5">
                {card.map((item)=>(
                    <div className="col-md-4 col-sm-6 mb-4 d-flex justify-content-center align-item-end" key={item.id}>
                    <div className="Team__card d-flex flex-column justify-content-end">
                        <img src={item.img} alt="" className='Team__card--img' />
                        <div className='d-flex justify-content-center Team__card--bg'>
                        <div className="Team__card__content py-2 text-center px-1">
                            <span className='Team__card__content__title1'>{item.name}</span> <br />
                            <span className='Team__card__content__title2'>{item.position}</span>
                        </div>
                        </div>
                    </div>
                </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Team