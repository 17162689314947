import React from 'react'
import './style.css'
import { Link } from 'react-router-dom';

function Policy() {
  return (
    <div className='Policy d-flex justify-content-center px-4'>
            <div className="Policy__container pb-5 pt-4">
                <h3 className="Policy__container--h3 text-uppercase pb-4">
                    Privacy Policy
                </h3>
                <h6 className="Policy__container--h6 text-uppercase">
                    Introduction
                </h6>
                <p className='Policy__container__para'>
                Welcome to rndmatch's Privacy Policy. We are committed to respecting and protecting your privacy and have written this Policy to be as clear and concise as possible.
                </p>
                <p className='Policy__container__para'>Together with our Terms and Conditions of Use (available <Link to={'/terms'} className='Policy__container__para--Link'>here</Link>) and any other documents referred to within them, this Policy explains how we collect, process and share your personal data when you visit our website and/or our online platform as well as when you use any services we provide through it. It also explains what privacy rights you have and can exercise. Please read it carefully and make sure you understand our views and practices regarding your personal data and how we will treat it.</p>
                <p className='Policy__container__para'>"Personal data" or "personal information" means information that (either in isolation or in combination with other information held by us) enables you to be identified as an individual or recognised directly or indirectly</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                   DATA CONTROLLER
                </h6>
                <p className='Policy__container__para'>Rndmatch Limitedis the "data controller" (as defined in the General Data Protection Regulation (EU) 2016/679 ("GDPR") and the UK Data Protection Act 2018 ("DPA 2018")) and is responsible for your personal data. In this Privacy Policy ("Policy") it is collectively referred to as "Rndmatch Limited", "rndmatch", "we", "us" or "our".</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                CONTACT DETAILS
                </h6>
                <ul className='Policy__container__list list-unstyled'>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>Full name:</span> 
                    <span>RndmatchLimited</span></li>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>Website address:</span> 
                    <span> rndmatch.com</span></li>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>E-mail address:</span> 
                    <span> support@rndmatch.com</span></li>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>Registered and postal address:</span> 
                    <span>5 Briskman Way, Aylesbury HP21 8FP, United Kingdom</span></li>
                    <li className="Policy__container__list__item mb-2"> You can contact us at any time using the email address above if you have any queries regarding this Privacy Policy and/or you wish to exercise any of your privacy rights.</li>
                </ul>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                INFORMATION COLLECTION
                </h6>
                <p className='Policy__container__para'>We will collect and process the following data about you:</p>
                <h6 className="Policy__container--h6 pt-3">
                Information you provide to us
                </h6>
                <p className='Policy__container__para'>We may collect personal information about you that you give us when you interact with us. This may be when you are filling in forms on www.rndmatch.com (our site) or by corresponding with us by phone, e-mail or otherwise. This may include data that can be used to identify you or any other individual to whom the data relates. It includes information you provide to us when you register to use our site, subscribe to our service, participate in social media functions on our site, view the profiles of other users, report a problem with our site and when you use our merchandise store and claim referral gifts.</p>
                <p className='Policy__container__para'>The information you give us may include your name, nationality, work preferences, visa requirements and work eligibility in different countries, e-mail address and phone number, details of your current employment and past ones (such as company name, role, salary, start and end dates), details of your education, personal description, and photograph. The information you additionally give us when buying or claiming products from our merchandise store may include your address.</p>
                <p className='Policy__container__para'>If you provide personal information to us about another person, you confirm that you have the authorisation of that person to provide us with this information.</p>
                <p className='Policy__container__para'>Please refrain from giving us any sensitive data relating to your race or ethnic origin, physical or mental health, religious or philosophical beliefs, commission of criminal offences or proceedings, political beliefs, or sexual life. If you do provide information relating to any of the above to us, you should only do so if you are content for us to process this information in accordance with this Privacy Policy.</p>
                <h6 className="Policy__container--h6 pt-3">
                Information we collect about you
                </h6>
                <p className='Policy__container__para'>In relation to each of your visits to our site we will automatically collect the following information:</p>
                <ul className='Policy__container__list'>
                    <li className="Policy__container__list__item mb-2">technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform;</li>
                    <li className="Policy__container__list__item mb-2">information about your visit, including the full Uniform Resource Locators (URL), clickstream to, through and from our site (including date and time), page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page.</li>
                </ul>
                <p className='Policy__container__para'>We may also collect and use aggregated data, such as statistical or demographic data. For example we may aggregate your usage data to calculate the percentage of users accessing a specific website feature. While aggregated data which could be derived from your personal data is not considered personal data in law as it will not directly or indirectly reveal your identity, if we do combine or connect aggregated data with your personal data so that it can directly or indirectly identify you, we will treat the combined data as personal data and it will be used in accordance with this Policy.</p>
                <p className='Policy__container__para'>We may augment your personal data with diversity data using a diversity classifier API, which infers demographic information based on user names. We use this data to understand the make-up of our users. This inferred data is never displayed or shared at an individual level.</p>
                <h6 className="Policy__container--h6 pt-3">
                Information we receive about you from other sources
                </h6>
                <p className='Policy__container__para'>We work closely with third parties (including, recruiting platforms, analytics providers, and payroll processing platforms) and we may receive personal information about you from those third parties as well as from publicly available sources.</p>
                <p className='Policy__container__para'>For example, we may receive information about you if you log in to our site using Google, Facebook, GitHub, Linkedin, or StackoverFlow. If you do log in using any of these services we may receive information from these services which makes it easier for you to log in to our site.</p>
                <p className='Policy__container__para'>This information is not collected by us but by a third party and is subject to the relevant third party’s own separate privacy policies. We do not have any control or input on how your personal data is handled by third parties so if you have any questions you should contact the relevant third party for further information about your personal data.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                Cookies
                </h6>
                <p className='Policy__container__para'>When you visit our site, our site may use cookies and other technologies to distinguish you from other users of our site. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. The cookies and other technologies may automatically collect:</p>
                <ul className='Policy__container__list'>
                    <li className="Policy__container__list__item mb-2">Technical information, including your IP address, your login information, browser type and version, device identifier, location and time zone setting, browser plug-in types and versions, operating system and platform, page response times and download errors.</li>
                    <li className="Policy__container__list__item mb-2">information about your visit, including the other websites you visit before and after our website and products you viewed or searched for.</li>
                    <li className="Policy__container__list__item mb-2">the length of your visits to certain pages, page interaction information (such as scrolling, clicks and mouseovers).</li>
                </ul>
                <p className='Policy__container__para'>For detailed information on the cookies we use and the purposes for which we use them see our Cookie Policy, which is available <Link to={'/cookies'} className='Policy__container__para--Link'>here</Link>.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                USES MADE OF THE INFORMATION
                </h6>
                <p className='Policy__container__para'>We use information held about you to provide, maintain and develop our products and services, and to understand how to improve them.</p>
                <p className='Policy__container__para'>For example, we will use this information:</p>
                <ul className='Policy__container__list'>
                    <li className="Policy__container__list__item mb-2">to carry out our obligations arising from any contracts entered into between you and us and to provide you with the information and services that you request from us.</li>
                    <li className="Policy__container__list__item mb-2">to create and complete your profile on our site and identify you as a user in our system.</li>
                    <li className="Policy__container__list__item mb-2">to provide you with information about other services we offer that are similar to those that you have already purchased or enquired about.</li>
                    <li className="Policy__container__list__item mb-2">to notify you about changes to our service.</li>
                    <li className="Policy__container__list__item mb-2">to ensure that content from our site is presented in the most effective manner for you and for your computer (for example, we may aggregate your information in an anonymised form to generate usage statistics).</li>
                    <li className="Policy__container__list__item mb-2">to run internal operations and to administer our site , including troubleshooting, data analysis, testing, research, statistical and survey purposes.</li>
                    <li className="Policy__container__list__item mb-2">to enable you to access our service, your account , and participate in interactive features of our service if you choose to do so.</li>
                    <li className="Policy__container__list__item mb-2">to keep our site safe and secure.</li>
                    <li className="Policy__container__list__item mb-2">to make suggestions and recommendations to you and other users of our site about opportunities that may interest you or them.</li>
                    <li className="Policy__container__list__item mb-2">with your consent, send you our newsletter.</li>
                </ul>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                DISCLOSURE OF YOUR INFORMATION
                </h6>
                <p className='Policy__container__para'>We may share your personal information with selected third parties including business partners, suppliers, customers and service providers for the performance of any contract we enter into with them or you. For example, we may share your email with the person you wish to connect with. We may also share your personal data with analytics and search engine providers that assist us in the improvement and optimisation of our site. However, we will only provide our service providers with the information that is necessary for them to perform the services, and not for any other purpose.</p>
                <p className='Policy__container__para'>Our website may contain links to and from the websites of our partners. If you follow a link to any of these websites, please note that these third parties have their own privacy notices and that we have no control over how they may use your personal information. You should check the privacy notices of third parties before you submit any personal information to them.</p>
                <p className='Policy__container__para'>In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets.</p>
                <p className='Policy__container__para'>If Rndmatch Limited (or substantially all of its assets) are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets.</p>
                <p className='Policy__container__para'>We may also share your personal data if we are under a duty to disclose or share them in order to comply with any legal obligation, or in order to enforce or apply our legal rights.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                TRANSFER OF YOUR INFORMATION OUTSIDE OF THE EEA
                </h6>
                <p className='Policy__container__para'>We store and process personal data on servers located in the European Economic Area ("EEA"). Your personal data may be transferred to, and processed in a destination outside the EEA, including in regions including North America, Asia and Asia Pacific by our staff when they are operating outside the EEA or by one of our service providers.</p>
                <p className='Policy__container__para'>By providing us with your personal information, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your personal information is treated securely and in accordance with this Privacy Policy and applicable data protection laws, including, where relevant, entering into EU standard contractual clauses (or equivalent measures) with the party outside the EEA receiving the personal information</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                LEGAL BASIS FOR PROCESSING YOUR INFORMATION
                </h6>
                <p className='Policy__container__para'>Our legal basis for collecting and processing personal data will depend on the data concerned and the specific context in which we collect it, however, we will normally collect these data from you only:</p>
                <ol className='Policy__container__list' type="a">
                    <li className="Policy__container__list__item mb-2">when we need the personal data to perform a contact with you,</li>
                    <li className="Policy__container__list__item mb-2">where we have your consent,</li>
                    <li className="Policy__container__list__item mb-2">or where the processing is in our legitimate interest which is not overridden by your individual rights.</li>
                </ol>
                <p className='Policy__container__para'>If we collect or otherwise process your personal information in reliance on a different legal basis, such as to protect your vital interest or to comply with a legal requirement, we will make it clear at the relevant time.</p>
                <p className='Policy__container__para'>If we collect and process your personal information in reliance on our legitimate interest, this interest will normally be to operate our product and services, and to communicate with you to provide them to you. We may have other legitimate interests and if appropriate, we will make clear to you at the relevant time what these are.</p>
                <p className='Policy__container__para'>If you have any queries about the basis on which we process your personal data or need further information, please contact us at support@rndmatch.com</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                HOW WE PROTECT YOUR PERSONAL DATA
                </h6>
                <p className='Policy__container__para'>We are constantly reviewing and updating our technical and organisational security measures to protect against loss, misuse or alteration of personal data.</p>
                <p className='Policy__container__para'>Where you have selected a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</p>
                <p className='Policy__container__para'>The transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                DATA RETENTION
                </h6>
                <p className='Policy__container__para'>We will only retain your personal data for as long as it is necessary for the purpose it was collected and to the extent permitted by law. When this is no longer the case, we will remove it from our systems and records and/or take steps to promptly anonymise it so that you can no longer be identified from it (unless we need to keep your information to comply with our legal or regulatory obligations).</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                YOUR RIGHTS
                </h6>
                <p className='Policy__container__para'>If you are in the EEA or in the United Kingdom, you have the following rights (where applicable) provided to you by the GDPR and DPA 2018, respectively:</p>
                <ul className='Policy__container__list list-unstyled'>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>Access.</span> 
                    <span>You have the right to request a copy of your personal information we are processing.</span></li>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>Rectification.</span> 
                    <span>You have the right to have incomplete or inaccurate personal information that we process about you completed or rendered accurate.</span></li>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>Deletion.</span> 
                    <span>You have the right to request that we delete personal information that we process about you.</span></li>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>Restriction. </span> 
                    <span>You have the right to restrict our processing of your personal information where you believe such data to be inaccurate; our processing is unlawful; or that we no longer need to process such data for a particular purpose.</span></li>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>Data Portability.</span> 
                    <span>You have the right to obtain personal information we hold about you, in a structured, electronic format, and to transmit such personal information to another data controller.</span></li>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>Objection.</span> 
                    <span>You have the right to object to processing of your personal information.</span></li>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>Withdrawing Consent. </span> 
                    <span>If you consented to our processing of your personal information, you have the right to withdraw your consent at any time, free of charge. This includes cases where you wish to opt out from marketing messages that you receive from us.</span></li>
                </ul>
                <p className='Policy__container__para'>You can make a request to exercise any of these rights in relation to your personal information by sending the request by email to support@rndmatch.com</p>
                <p className='Policy__container__para'>You have the right to lodge a complaint with the local data protection authority if you believe that we have not complied with applicable data protection laws. We would, however, appreciate the chance to deal with your concerns before you approach a data protection authority, so please contact us in the first instance.</p>
                <p className='Policy__container__para'>If you are based in, or the issue relates to, the UK, the Information Commissioner’s Office can be contacted using the following details:</p>
                <ul className='Policy__container__list'>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>telephone:</span> 
                    <span> +44 0303 123 1113</span></li>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>E-mail:</span> 
                    <a href='mailto:support@rndmatch.com' className='text-decoration-none Policy__container__list__item--Link'> casework@ico.org.uk</a></li>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>website:</span> 
                    <span>www.ico.org.uk</span></li>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>web-form: </span> 
                    <span>www.ico.org.uk/concerns/</span></li>
                    <li className="Policy__container__list__item mb-2"><span className='Policy__container__list__item--bold me-1'>address: </span> 
                    <span>Water Lane, Wycliffe House, Wilmslow, Cheshire, SK9 5AF</span></li>
                </ul>
                <p className='Policy__container__para'>If you are based in, or the issue you would like to complain about took place elsewhere in the EEA, please find a list of local data protection authorities in the other EEA countries <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en" className='Policy__container__para--Link text-decoration-none'>here</a></p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                UPDATES TO THIS PRIVACY POLICY
                </h6>
                <p className='Policy__container__para'>We may update this Policy from time to time. When this happens, we will notify you by posting the updated version on this page. We advise you to review this Policy periodically for any updates. You can see when this Policy was last updated by checking the "last updated" date displayed at the bottom of the Policy. Changes are effective when they are posted on this page.</p>
                <p className='Policy__container__para'>If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided to us, or by placing a prominent notice on our website. We may notify you in advance.</p>
                <p className='Policy__container__para'>Last updated: 30 December 2022</p>
            </div>
        </div>
  )
}

export default Policy