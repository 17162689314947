import React from 'react'
import './style.css'
import logo from '../../Images/logo.png'
import { Link } from 'react-router-dom'
import {TiSocialFacebook} from 'react-icons/ti'
import {AiOutlineTwitter} from 'react-icons/ai'
import {FiInstagram} from 'react-icons/fi'
import {FaYoutube, FaLinkedinIn} from 'react-icons/fa'

function Footer() {
  return (
    <div className='Footer'>
        <div className="container-md pt-5 pb-4 ps-md-2 ps-3">
            <div className="row">
                <div className="col-lg-6 pe-5 ps-md-3 ps-4">
                <a href='/' className="Navbar__brand navbar-brand">
                    <img src={logo} alt="Logo" width={150}/>
                </a>
                <p className='Footer__para mt-4'>
                    rndmatch is a machine learning recruitment platform for the R&D sector. We empower hiring teams large and small to access top talent without any friction. We also help accelerate the  career potential of R&D candidates; they can now sit back and wait for employers to apply to   interview them. It’s a win-win!
                </p>
                </div>
                <div className="col-lg-2 col-sm-4 ps-4">
                    <h5 className='Footer__heading mb-0 mt-2'>How it works</h5>
                    <ul className='Footer__menu list-unstyled mt-4'>
                        <li className="Footer__menu__item">
                            <Link to={`/employers`} className="Footer__menu__item__link text-decoration-none">Employers</Link>
                        </li>
                        <li className="Footer__menu__item my-2">
                            <Link to={`/candidates`} className="Footer__menu__item__link text-decoration-none">Candidates</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-2 col-sm-4 ps-lg-4 ps-4">
                    <h5 className='Footer__heading mb-0 mt-2'>Product</h5>
                    <ul className='Footer__menu list-unstyled mt-4'>
                        <li className="Footer__menu__item">
                            <Link to={`/solution`} className="Footer__menu__item__link text-decoration-none">Solution</Link>
                        </li>
                        <li className="Footer__menu__item my-2">
                            <Link to={`/pricing`} className="Footer__menu__item__link text-decoration-none">Pricing</Link>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-2 col-sm-4 ps-4">
                    <h5 className='Footer__heading mb-0 mt-2'>rndmatch</h5>
                    <ul className='Footer__menu list-unstyled mt-4'>
                        <li className="Footer__menu__item">
                            <Link to={`/about`} className="Footer__menu__item__link text-decoration-none">About</Link>
                        </li>
                        <li className="Footer__menu__item my-2">
                            <Link to={`/contact`} className="Footer__menu__item__link text-decoration-none">Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="Footer__copyright d-md-flex text-center justify-content-between mt-4 align-items-end border-top pt-3">
            <p className='Footer__copyright__text mb-0'>&copy; rndmatch Limited</p>
            
            <div className="Footer__container d-md-flex">
            <ul className='d-flex list-unstyled footer-copyright__list justify-content-center me-md-4 mb-0 mt-md-0 mt-2'>
                <li className='Footer__copyright__list__item Footer__copyright__text d-flex align-items-end'><Link className='text-decoration-none Footer__copyright__list__item--Link' to={'/privacy'}>Privacy</Link></li>
                <li className='Footer__copyright__list__item Footer__copyright__text d-flex align-items-end mx-4'><Link className='text-decoration-none Footer__copyright__list__item--Link' to={'/terms'}>Terms</Link></li>
                <li className='Footer__copyright__list__item Footer__copyright__text d-flex align-items-end'><Link className='text-decoration-none Footer__copyright__list__item--Link' to={'/cookies'}>Cookies</Link></li>
            </ul>
                    <div className='d-flex justify-content-center mt-md-0 mt-3'>
                    <div className="Footer__container--div d-flex align-items-center justify-content-center">
                        <a href="https://www.facebook.com/" target="-blank" className='d-flex align-items-center justify-content-center'>
                        <TiSocialFacebook className='fs-6 Footer__container--div__icon' />
                        </a>
                    </div>
                    <div className="Footer__container--div Footer__container--div2  d-flex align-items-center justify-content-center mx-2">
                        <a href="https://www.twitter.com/" target="-blank" className='d-flex align-items-center justify-content-center'>
                        <AiOutlineTwitter className='fs-6 Footer__container--div2__icon2' />
                        </a>
                    </div>
                    <div className="Footer__container--div Footer__container--div3  d-flex align-items-center justify-content-center me-2">
                        <a href="https://www.linkedin.com/" target="-blank" className='d-flex align-items-center justify-content-center'>
                        <FaLinkedinIn className='fs-6 Footer__container--div3__icon3' />
                        </a>
                    </div>
                    <div className="Footer__container--div Footer__container--div4  d-flex align-items-center justify-content-center me-2">
                        <a href="https://www.instagram.com/" target="-blank" className='d-flex align-items-center justify-content-center'>
                        <FiInstagram className='fs-6 Footer__container--div4__icon4' />
                        </a>
                    </div>
                    <div className="Footer__container--div Footer__container--div5 d-flex align-items-center justify-content-center">
                        <a href="https://www.youtube.com/" target="-blank" className='d-flex align-items-center justify-content-center'>
                        <FaYoutube className='fs-6 Footer__container--div5__icon5' />
                        </a>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer