import React, {useState} from 'react'
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Select from 'react-select'
import { Link } from 'react-router-dom';
import Footer from "./Components/Footer/Footer";
import Cards from "./Components/Front/Cards/Cards";
import Hero from "./Components/Front/Hero/Hero";
import Job from "./Components/Front/Job/Job";
import Recruiting from "./Components/Front/Recruiting/Recruiting";
import Video from "./Components/Front/Video/Video";
import Navbar from "./Components/Navbar/Navbar";
import Sidebar from "./Components/Sidebar/Sidebar";
import Login from "./Components/Login/Login";
import Signup from "./Components/EmployerSignUp/Signup";
import Banner from "./Components/About/Banner/Banner";
import AVideo from "./Components/About/AVideo/AVideo";
import Team from "./Components/About/Team/Team";
import Contact from "./Components/Contact/Contact";
import Candidates from "./Components/Candidates/Candidates";
import Form from "./Components/Employers/Form/Form";
import ECards from './Components/Employers/Cards/Cards';
import EVideo from "./Components/Employers/Video/EVideo";
import Content from "./Components/Solution/Content/Content";
import Cards1 from "./Components/Solution/Cards1/Cards1";
import Cards2 from "./Components/Solution/Cards2/Cards2";
import SignUp from './Components/CandidateSignUp/SignUp';
import arrow from './Images/CandidateSignup/arrow.png';
import arrow2 from './Images/CandidateSignup/arrow2.png';
import {FiUpload} from 'react-icons/fi';
import CandidateImg2 from './Images/CandidateSignup/Candidate Signup2.jpg';
import CandidateImg3 from './Images/CandidateSignup/Candidate Signup3.jpg';
import CandidateImg4 from './Images/CandidateSignup/Candidate Signup4.jpg';
import CandidateImg5 from './Images/CandidateSignup/Candidate Signup5.jpg';
import CandidateImg6 from './Images/CandidateSignup/Candidate Signup6.jpg';
import CandidateImg7 from './Images/CandidateSignup/Candidate Signup7.jpg';
import dot from './Images/CandidateSignup/dot.png';
import CandidateStep1 from './Components/CandidateSignUp/CandidateStep1';
import Pricing from './Components/Pricing/Pricing';
import Top from './Components/Privacy/Top/Top';
import Policy from './Components/Privacy/Policy/Policy';
import Terms from './Components/Privacy/Terms';
import Cookies from './Components/Privacy/Cookies';
import AcceptableUse from './Components/Privacy/AcceptableUse';

const Styles = {
  control: (base, state) => ({
    ...base,
    '&:hover': { borderColor: '#B7C93B' }, 
      border: '1px solid #B7C93B',
      borderRadius: '10px',
      boxShadow: '0px 15px 43px rgba(160,168,189,0.3)',
  }),
  option: (base, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...base,
      backgroundColor: isFocused ? "#B7C93B" : "white",
      background: isSelected ? "#B7C93B" : "white",
      '&:hover': { backgroundColor: isSelected ? "#B7C93B": '#B7C93B', color: isSelected ? "#fff": "#fff", opacity: isSelected? '1': '0.7' },
      Transition: "all 0.5s ease"
    };
},
  menuList: (base) => ({
      ...base,
      '&:hover': { borderColor: 'lightgray' }
    }),
    multiValue: (styles, {data}) =>{
      return {
        ...styles,
        backgroundColor: "#B7C93B",
        color: '#fff',
        fontFamily: `'Red Hat Text', sans-serif`
      }
    },
    multiValueLabel: (styles, {data}) =>{
      return {
        ...styles,
        color: '#fff',
        // fontWeight: '500'
      }
    },
    multiValueRemove: (styles, {data}) =>{
      return {
        ...styles,
        color: '#fff',
        ':hover':{
          color: "#fff",
          backgroundColor: "#B7C93B",
        }
      }
    }
};
const options = [
  { value: 'San Francisco', label: 'San Francisco' },
  { value: 'CA', label: 'CA' },
  { value: 'USA1', label: 'USA' },
  { value: 'UAE2', label: 'UAE' },
  { value: 'UAE3', label: 'UAE' },
  { value: 'UAE4', label: 'UAE' },
  { value: 'UAE5', label: 'UAE' },
  { value: 'Paris', label: 'Paris' },
]  
const salary = [
  { value: '20,000', label: '20,000' },
  { value: '25,000', label: '25,000' },
  { value: '30,000', label: '30,000' },
  { value: '35,000', label: '35,000' },
  { value: '40,000', label: '40,000' },
  { value: '45,000', label: '45,000' },
  { value: '50,000', label: '50,000' },
  { value: '55,000', label: '55,000' },
  { value: '60,000', label: '60,000' },
  { value: '65,000', label: '65,000' },
  { value: '70,000', label: '70,000' },
  { value: '75,000', label: '75,000' },
  { value: '80,000', label: '80,000' },
  { value: '85,000', label: '85,000' },
  { value: '90,000', label: '90,000' },
  { value: '95,000', label: '95,000' },
  { value: '100,000', label: '100,000' },
  { value: '115,000', label: '115,000' },
  { value: '130,000', label: '130,000' },
  { value: '145,000', label: '145,000' },
  { value: '150,000+', label: '150,000+' },
]
const unit = [
  { value: 'GBP', label: 'GBP' },
  { value: 'EUR', label: 'EUR' },
  { value: 'USD', label: 'USD' },
]

function App(props) {
  const [click1, setClick1] = useState(false);
  const [click2, setClick2] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [price, setPrice] = useState([]);
  const [punit, setPunit] = useState([]);
  const [select, setSelect] = useState(false);
  const [select2, setSelect2] = useState(false);
  const [next, setNext] = useState(false);
  const [upload, setUpload] = useState('Upload Your CV');
  
// email validation starts
  const [message, setMessage] = useState('');
  const [disable, setDisable] = useState(true);
  const [imessage, setImessage] = useState('');
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const inputhandleChange = event => {
    if (!isValidEmail(event.target.value)) {
      setDisable(true)
    } else {
      setDisable(false)
      setImessage('')
    }
    setMessage(event.target.value);
  };
// email validation ends

//  password validation starts
const [password, setPassword] = useState('');
const [pmessage, setPmessage] = useState('');
const [pdisable, setPdisable] = useState(true);
const passwordhandleChange = event => {
  if (event.target.value.length < 8) {
    setPdisable(true)
  } else {
    setPdisable(false)
    setPmessage('')
  }
  setPassword(event.target.value);
};
// password validation ends

// upload file validation starts
const handleChange = event => {
  const uploadedfilename = document.getElementById('uploaded-file-name')
  if (event.target.files.length > 0) {
    let filename = event.target.files[0].name;
    console.log(filename)
    setNext(true)
    setUpload('Uploaded')
    uploadedfilename.innerHTML= filename
   }  
};
// upload file validation ends

// react-selects validation
function handleChange1(o){
  setSelectedOptions(o);
  if(selectedOptions.length === 0){
    setVisible(true);
  }
  else
  {
    setVisible(false);
  }
}
function PRICE(o){
  setPrice(o);
  if(price.length === 0){
    setSelect(true)
  }
  else
  {
    setSelect(false);
  }
}
function PUNIT(o){
  setPunit(o);
  if(punit.length === 0){
    setSelect2(true)
  }
  else
  {
    setSelect2(false);
  }
}
// react-selects validation ends
  return (
    <div className="App">
          <Routes>
          <Route path={`/`}
          element={
            <>
              <Navbar 
              class= "Navbar-light"
              color='#fff'
              Scolor= "#fff"
              />
              <Sidebar 
              content= {
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Hero />
              <Cards />
              <Video />
              <Recruiting />
              <Job />
              <Footer />
            </>
          }
        />
        <Route path={`/login`}
          element={
            <>
              <Navbar 
              class= "Navbar-light"
              color='#fff'
              Scolor='#fff'
              />
              <Login />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Footer />
            </>
          }
        />
        <Route path={`/about`}
          element={
            <>
              <Navbar 
              class= "Navbar-transparent"
              color='#fff'
              Scolor= '#fff'
              />
              <Banner />
              <AVideo />
              <Team />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2 hr-focused"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Footer />
            </>
          }
        />
        <Route path={`/contact`}
          element={
            <>
              <Navbar 
              class= "Navbar-light"
              color='#fff'
              Scolor='#fff'
              />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2 hr-focused"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Contact />
              <Footer />
            </>
          }
        />
        <Route path={`/candidates`}
          element={
            <>
              <Navbar 
              class= "Navbar-light"
              color='#fff'
              Scolor='#fff'
              />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2 hr-focused"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Candidates />
              <Footer />
            </>
          }
        />
        <Route path={`/employer`}
          element={
            <>
              <Navbar 
              class= "Navbar-light"
              color='#fff'
              Scolor='#fff'
              />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Signup />
              <Footer />
            </>
          }
        />
        <Route path={`/employers`}
          element={
            <>
              <Navbar 
              class= "Navbar-grey"
              color='#fff'
              Scolor='#fff'
              />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2 hr-focused"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Form />
              <ECards />
              <EVideo />
              <Footer />
            </>
          }
        />
        <Route path={`/solution`}
          element={
            <>
              <Navbar 
              class= "Navbar-light"
              color='#fff'
              Scolor='#fff'
              />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2 hr-focused"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Content />
              <Cards1 />
              <Cards2 />
              <Footer />
            </>
          }
        />
        <Route path={`/pricing`}
          element={
            <>
              <Navbar 
              class= "Navbar-light"
              color='#fff'
              Scolor='#fff'
              />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2 hr-focused"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Pricing />
              <Footer />
            </>
          }
        />
        <Route path={`/privacy`}
          element={
            <>
              <Top 
              content= {
                <ul className='Top__list d-flex list-unstyled justify-content-center mt-4 pb-2'>
              <li className='Top__list__item mx-sm-5'><Link to={'/privacy'} className= 'Top__list__item--Link Top__list__item--Link--bold text-decoration-none'>Privacy</Link></li>
              <li className='Top__list__item mx-sm-5 Top__list__item--mid'><Link to={'/terms'} className='Top__list__item--Link text-decoration-none'>Terms</Link></li>
              <li className='Top__list__item mx-sm-5'><Link to={'/cookies'} className='Top__list__item--Link text-decoration-none'>Cookies</Link></li>
            </ul>
              }
              />
              <Policy />
            </>
          }
        />
        <Route path={`/terms`}
          element={
            <>
              <Top 
              content= {
                <ul className='Top__list d-flex list-unstyled justify-content-center mt-4 pb-2'>
              <li className='Top__list__item mx-sm-5'><Link to={'/privacy'} className= 'Top__list__item--Link text-decoration-none'>Privacy</Link></li>
              <li className='Top__list__item mx-sm-5 Top__list__item--mid'><Link to={'/terms'} className='Top__list__item--Link Top__list__item--Link--bold  text-decoration-none'>Terms</Link></li>
              <li className='Top__list__item mx-sm-5'><Link to={'/cookies'} className='Top__list__item--Link text-decoration-none'>Cookies</Link></li>
            </ul>
              }
              />
              <Terms />
            </>
          }
        />
        <Route path={`/cookies`}
          element={
            <>
              <Top 
              content= {
                <ul className='Top__list d-flex list-unstyled justify-content-center mt-4 pb-2'>
              <li className='Top__list__item mx-sm-5'><Link to={'/privacy'} className= 'Top__list__item--Link text-decoration-none'>Privacy</Link></li>
              <li className='Top__list__item mx-sm-5 Top__list__item--mid'><Link to={'/terms'} className='Top__list__item--Link text-decoration-none'>Terms</Link></li>
              <li className='Top__list__item mx-sm-5'><Link to={'/cookies'} className='Top__list__item--Link Top__list__item--Link--bold text-decoration-none'>Cookies</Link></li>
            </ul>
              }
              />
              <Cookies />
            </>
          }
        />
        <Route path={`/acceptable-use`}
          element={
            <>
              <Top 
              content= {
                <ul className='Top__list d-flex list-unstyled justify-content-center mt-4 pb-2'>
              <li className='Top__list__item mx-sm-5'><Link to={'/privacy'} className= 'Top__list__item--Link text-decoration-none'>Privacy</Link></li>
              <li className='Top__list__item mx-sm-5 Top__list__item--mid'><Link to={'/terms'} className='Top__list__item--Link text-decoration-none'>Terms</Link></li>
              <li className='Top__list__item mx-sm-5'><Link to={'/cookies'} className='Top__list__item--Link text-decoration-none'>Cookies</Link></li>
            </ul>
              }
              />
              <AcceptableUse />
            </>
          }
        />
        <Route path={`/candidate_step1`}
          element={
            <>
              <Navbar 
              class= "Navbar-grey"
              color='#F5F5F5'
              />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <CandidateStep1 />
              <Footer />
            </>
          }
        /> 
        <Route path={`/candidate_step2`}
          element={
            <>
              <Navbar 
              class= "Navbar-grey"
              color='#F5F5F5'
              />
              <SignUp 
              image= {CandidateImg2}
              content= {
                <>
                        <div className="row d-flex justify-content-md-start justify-content-center">
                            <div className="col-md-12 col-sm-8">
                            <h5 className='Signup__right__heading2 mt-5'>Where are you eligible to work without sponsorship?</h5>
                            <Select isMulti options={options} styles={Styles} placeholder="Country (no visa required)" className=' mt-1 Signup__right--select Signup__right--select2'
                            onChange={handleChange1}
                            isOptionDisabled={() =>selectedOptions.length >= 5}
                            />
                            <p className="ms-1 Signup__right__detail mt-3">Choose country where you have the right to work and live</p>
                            </div>
                            <div className="col-sm-8 d-flex align-items-center">
                          
                          {visible&& <button className='Signup__right--button py-2 px-3 mb-5 mt-4'
                          >
                            <Link to={`/candidate_step3`} className="Signup__right--button--Link text-decoration-none d-flex align-items-center">
                                Next 
                                <img src={arrow} alt="" className='Signup__right--button--img ms-2'/>
                            </Link>
                         </button>}
                        
                            </div>
                            
                        </div>
                </>
              }
              />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Footer />
            </>
            }
            />
        <Route path={`/candidate_step3`}
          element={
            <>
              <Navbar 
              class= "Navbar-grey"
              color='#F5F5F5'
              />
              <SignUp 
              image= {CandidateImg3}
              content= {
                <>
                
                        <div className="row">
                            <div className="col-12 d-flex justify-content-md-start justify-content-center">
                              <div>
                              <h5 className='Signup__right__heading2 mt-5'>What job type are you looking for? (pick one or both)</h5>
                            <button className='Signup__right--button Signup__right--button-option py-2 px-4 me-2 mb-2' onClick={(e)=>setClick1(current => !current)}
                            style={{
                              background: click1 ? '#B7C93B' : '#fff', 
                          }}
                            >
                                Permanent
                            </button>
                            <button className='Signup__right--button Signup__right--button-option py-2 px-4 me-4 mb-4' onClick={(e)=>setClick2(current => !current)}
                            style={{
                              background: click2 ? '#B7C93B' : '#fff', 
                          }}>
                                Temporary
                            </button>
                            <button className='Signup__right--button py-2 px-3 my-lg-0 mb-5' 
                            style={{
                              display: click1 || click2 ? 'inline-block' : 'none', 
                          }}
                            >
                            <Link to={`/candidate_step4`} className="Signup__right--button--Link text-decoration-none d-flex align-items-center">
                                Next 
                                <img src={arrow} alt="" className='Signup__right--button--img ms-2'/>
                            </Link>
                         </button>
                            </div>
                            </div>
                        </div>
                </>
              }
              />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Footer />
            </>
          }
        />  
        <Route path={`/candidate_step4`}
          element={
            <>
              <Navbar 
              class= "Navbar-grey"
              color='#F5F5F5'
              />
              <SignUp 
              image= {CandidateImg4}
              content= {
                <>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-md-start justify-content-center">
                            <div>
                            <h5 className='Signup__right__heading2 mt-5'>Setup your profile</h5>
                            <button className='Signup__right--button Signup__right--button-upload py-2 ps-3 pe-4 me-4 mb-4'>
                            <input type="file" name="file" id="file" className="inputfile" onChange={handleChange}/>
                            <label htmlFor="file" className='inputfile--label'><FiUpload className='fs-5 me-3' style={{display: next? 'none': 'inline-block'}} />
                            <span className='pt-1'> {upload} </span><small id='uploaded-file-name'></small> </label> 
                            </button>
                            
                            {next && <button className='Signup__right--button py-2 px-3 my-lg-0 mb-5'>
                            <Link to={`/candidate_step5`} className="Signup__right--button--Link text-decoration-none d-flex align-items-center">
                                Next 
                                <img src={arrow} alt="" className='Signup__right--button--img ms-2'/>
                            </Link>
                         </button>}
                            </div>
                            </div>
                        </div>
                </>
              }
              />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Footer />
            </>
            }
          />
        <Route path={`/candidate_step5`}
          element={
            <>
              <Navbar 
              class= "Navbar-grey"
              color='#F5F5F5'
              />
              <SignUp 
              image= {CandidateImg5}
              content= {
                <>
                <div className="row justify-content-md-start justify-content-sm-center justify-content-start">
                            <div className="col-md-12 col-lg-8 col-sm-8  ">
                          <h5 className='Signup__right__heading2 mt-5'>What is your expected base salary (per annum)?</h5>
                           <div className=' Signup__right--select5-container'>
                           <Select options={salary} styles={Styles} placeholder="Eg. 20,000" onChange={PRICE} className='mb-3 me-2 Signup__right--select me-3 Signup__right--select5'/>
                            <Select options={unit} styles={Styles} placeholder="GBP" onChange={PUNIT} className='mb-3 Signup__right--select Signup__right--select5'/>
                           </div>
                            </div>
                            <div className='col-8'>
                            <div className='d-flex justify-content-start mt-2 mb-5'>
                          <button className='Signup__right--button my-3 py-2 px-3'
                          style={{
                            display: select && select2 ? 'block' : "none"
                          }}
                          >
                            <Link to={`/candidate_step6`} className="Signup__right--button--Link text-decoration-none d-flex align-items-center">
                                Next 
                                <img src={arrow} alt="" className='Signup__right--button--img ms-2'/>
                            </Link>
                         </button>
                  </div>
                            </div>
                        </div>
                        
                        
                </>
              }
              />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Footer />
            </>
          }
        />
        <Route path={`/candidate_step6`}
          element={
            <>
              <Navbar 
              class= "Navbar-grey"
              color='#F5F5F5'
              />
              <SignUp 
              image= {CandidateImg6}
              content= {
                <>
                <div className="row justify-content-md-start justify-content-sm-center justify-content-start">
                            <div className="col-md-12 col-lg-8 col-sm-8  ">
                          <h5 className='Signup__right__heading2 mt-5'>Enter your email address</h5>
                           <div className='d-flex Signup__right__password mb-2'  >
                           <input type={"email"} className="Signup__right__password--input" placeholder='Email Address' value={message}
                              onChange={inputhandleChange}/>
                           {disable? 
                           <button className="Signup__right__password--input--Link text-decoration-none">
                           <Link to={`/candidate_step7`} className=" text-decoration-none d-flex align-items-center justify-content-center" onClick={(e)=>{ e.preventDefault(); setImessage('Please enter a valid email address')}}>
                                <img src={arrow2} alt="" className='Signup__right__password--input--Link--img ms-2 me-2'/>
                            </Link>
                           </button> :
                           <button className="Signup__right__password--input--Link text-decoration-none">
                           <Link to={`/candidate_step7`} className=" text-decoration-none d-flex align-items-center justify-content-center">
                                <img src={arrow2} alt="" className='Signup__right__password--input--Link--img ms-2 me-2'/>
                            </Link>
                           </button>
                           }
                           </div>
                           <small className='Signup__right__small'>{imessage}</small>
                            </div>
                        </div>  
                </>
              }
              />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Footer />
            </>
          }
        />
        <Route path={`/candidate_step7`}
          element={
            <>
              <Navbar 
              class= "Navbar-grey"
              color='#F5F5F5'
              />
              <SignUp 
              image= {CandidateImg7}
              content= {
                <>
                <div className="row justify-content-md-start justify-content-sm-center justify-content-start">
                            <div className="col-md-12 col-lg-8 col-sm-8  ">
                          <h5 className='Signup__right__heading2 mt-5'>Create your password</h5>
                           <div className='d-flex Signup__right__password'>
                           <input type={"password"} className="Signup__right__password--input" minlength="8" placeholder='Make it strong' value={password}
                              onChange={passwordhandleChange}/>
                           {pdisable? 
                           <button className="Signup__right__password--input--Link text-decoration-none">
                           <Link to={`/`} className=" text-decoration-none d-flex align-items-center justify-content-center" onClick={(e)=>{ e.preventDefault(); setPmessage('Please enter 8 characters minimum')}}>
                                <img src={arrow2} alt="" className='Signup__right__password--input--Link--img ms-2 me-2'/>
                            </Link>
                           </button> :
                           <button className="Signup__right__password--input--Link text-decoration-none">
                           <Link to={`/`} className=" text-decoration-none d-flex align-items-center justify-content-center">
                                <img src={arrow2} alt="" className='Signup__right__password--input--Link--img ms-2 me-2'/>
                            </Link>
                           </button>
                           }
                           </div>
                            </div>
                            <div className="col-md-12 col-sm-8 pe-0">
                            <small className='Signup__right__small d-inline-block me-3 ms-1'><img src={dot} alt="" className='me-2'/>Min 8 characters</small>
                            <small className='Signup__right__small d-inline-block me-3 ms-1'><img src={dot} alt="" className='me-2'/>Uppercase</small>
                            <small className='Signup__right__small d-inline-block me-3 ms-1'><img src={dot} alt="" className='me-2'/>Lowercase</small>
                            <small className='Signup__right__small d-inline-block me-3 ms-1'><img src={dot} alt="" className='me-2'/>Number</small>
                            <small className='Signup__right__small'>{pmessage}</small>
                            <div className='d-flex ps-1 mt-3 align-items-start mb-5'>
                              <input type={"checkbox"} className="me-2 mt-1"/>
                              <span className='Signup__right--span'>I agree to rnd match <Link to={`/`} className="Signup__right--span--Link text-decoration-none">Privacy Policy & Terms of service</Link></span>
                            </div>
                          </div>

                        </div>      
                </>
              }
              />
              <Sidebar 
              content ={
                <ul className="Navbar__right__nav list-unstyled m-0">
            <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/employers'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Employers
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/candidates'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Candidates
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/solution'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Solution
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/pricing'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Pricing
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/about'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        About
                    </Link>
                </li>
                <li className="Navbar__right__nav__item mx-4 d-flex align-items-center my-3"  data-bs-dismiss="offcanvas" aria-label="Close">
                    <hr className="hr me-2"/>
                    <Link to={'/contact'} className="Navbar__right__nav__item__link Sidebar__link  text-decoration-none fs-6" >
                        Contact
                    </Link>
                </li>
            </ul>
              }
              />
              <Footer />
            </>
          }
        /> 
          </Routes>
    </div>
  );
}

export default App;
