import React from 'react'
import './style.css'
import Select from 'react-select'

const job = [
    { value: 'Candidate', label: 'Candidate' },
    { value: 'Employer', label: 'Employer' },
  ]
  const topic = [
    { value: 'Topic1', label: 'Topic1' },
    { value: 'Topic2', label: 'Topic2' },
  ]  
  const Styles = {
    control: (base, state) => ({
      ...base,
      '&:hover': { borderColor: '#B7C93B' }, 
        border: '0px solid #B7C93B',
        borderRadius: '10px',
        boxShadow: '0px 0px 0px rgba(160,168,189,0.3)',
        backgroundColor: '#fffff',
        fontFamily: 'Red Hat Text',
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#B7C93B" : "white",
        background: isSelected ? "#B7C93B" : "white",
        '&:hover': { backgroundColor: isSelected ? "#B7C93B": '#B7C93B', color: isSelected ? "#fff": "#fff", opacity: isSelected? '1': '0.7' },
        Transition: "all 0.5s ease"
      };
},
    menuList: (base) => ({
        ...base,
        '&:hover': { borderColor: 'lightgray' }
      }),
      multiValue: (styles, {data}) =>{
        return {
          ...styles,
          backgroundColor: "#B7C93B",
          color: '#fff',
          fontFamily: `'Red Hat Text', sans-serif`
        }
      },
      multiValueLabel: (styles, {data}) =>{
        return {
          ...styles,
          color: '#fff',
          // fontWeight: '500'
        }
      },
      multiValueRemove: (styles, {data}) =>{
        return {
          ...styles,
          color: '#fff',
          ':hover':{
            color: "#fff",
            backgroundColor: "#FFFFFF",
          }
        }
      }
  };

function Contact() {
  return (
    <div className='Contact mt-5 mb-5'>
        <div className="container-md py-5 px-md-1 px-3">
            <h1 className="Contact__heading text-center mt-5">
            Contact us
            </h1>
            <p className='Contact__detail text-center'>Please get in touch and our support team will answer all your questions.</p>
            <form action="/" className='Contact--form mx-auto'>
                <div className="row px-sm-5 ">
                    <div className="col-sm-6">
                        <input type="text" className='form-control form-control-lg Contact--input me-sm-3' placeholder='First Name *'/>
                    </div>
                    <div className="col-sm-6">
                        <input type="text" className='form-control form-control-lg Contact--input ms-sm-2' placeholder='Second Name *'/>
                    </div>
                </div>
                <div className="row px-sm-5">
                    <div className="col-sm-6">
                        <input type="email" className='form-control form-control-lg Contact--input me-sm-3' placeholder='Email *'/>
                    </div>
                    <div className="col-sm-6">
                        <input type="number" className='form-control form-control-lg Contact--input ms-sm-2' placeholder='Phone Number *'/>
                    </div>
                </div>
                <div className="row px-sm-5 justify-content-center">
                    <div className="col-md-6 pe-md-2 pe-sm-0">
                    <Select options={job} styles={Styles} placeholder="Employer or Candidate *" className='form-control px-0 form-control-lg Contact--select '/>
                    </div>
                    <div className="col-md-6 pe-md-2 pe-sm-0">
                    <Select options={topic} styles={Styles} placeholder="Select topic *" className='ms-md-2 form-control px-0 form-control-lg Contact--select '/>
                    </div>
                    <div className="col-12 pe-sm-0">
                    <textarea name="message" id="message" cols="10" rows="5" placeholder='Your Message *' className='form-control form-control-lg Contact--input Contact--textarea'></textarea>
                    </div>
                    <button type='submit' className='Contact--button'>Send</button>
                </div>
                
            </form>
        </div>
    </div>
  )
}

export default Contact