import React from 'react'
import './style.css'
import solution1 from '../../../Images/Solution/Solution1.png'
import solution2 from '../../../Images/Solution/Solution2.png'
import solution3 from '../../../Images/Solution/Solution3.png'
import solution4 from '../../../Images/Solution/Solution4.png'

const cards1=[
  {
    id:1, 
    img: solution1,
    heading: 'Research & Academic Recruiting'
  },
  {
    id:2, 
    img: solution2,
    heading: 'R&D Technical Recruiting'
  },
  {
    id:3, 
    img: solution3,
    heading: 'R&D Management Recruiting'
  },
  {
    id:4, 
    img: solution4,
    heading: 'Diversity Hiring'
  },
]

function Cards1() {
  return (
    <div className='Cards1 mb-sm-4'>
        <div className="container-md px-sm-5 px-4 d-flex flex-column align-items-center">
        <h1 className="Cards1__container__heading Content__text__heading text-center mx-auto d-md-none d-block">
              How 
              <span className='Cards1__container__heading--green Content__text__heading--green'> rndmatch </span> 
              helps you achieve your hiring goals
            </h1>
            <div className="Cards1__container d-flex flex-wrap Content__text text-sm-center justify-content-xl-start justify-content-center">
            <h1 className="Cards1__container__heading d-md-block d-none Content__text__heading text-center mx-auto">
              How 
              <span className='Cards1__container__heading--green Content__text__heading--green'> rndmatch </span> 
              helps you achieve your hiring goals
            </h1>
                {cards1.map((item)=>(
                  <div className="Cards1__container__card px-4 py-4 my-3 d-flex flex-column align-items-center justify-content-center me-xl-4 mx-3" key={item.id}>
                  <img src={item.img} alt=""  className='Cards1__container__card--img'/>
                  <h5 className="Cards1__container__card__heading text-center mt-4">{item.heading}</h5>
                </div>
                ))}
              </div>
            </div>
          </div>
  )
}

export default Cards1