import React from 'react'
import './style.css'
import logo from '../../../Images/logo.png'
import { Link } from 'react-router-dom'
function Top(prop) {
  return (
    <div className='Top pt-4 px-4'>
            <div className="Top__navbar d-flex justify-content-between align-items-end">
            <Link to={'/'} className="Navbar__brand navbar-brand">
                <img src={logo} alt="Logo" className='Navbar__brand__img'/>
            </Link>
            <Link to={'/login'} className='Top__navbar__right text-decoration-none'>Login</Link>
            </div>
            <div className="Top__header mt-5 text-center">
              <h1 className="Top__header__heading pt-3">
                rndmatch <span className='Top__header__heading--weight'>Legal</span>
              </h1>
            </div>
            {prop.content}
        </div>
  )
}

export default Top