import React from 'react'
import './style.css'
import employer from '../../../Images/Employers.png'
import { Link } from 'react-router-dom'

function EVideo() {
  return (
    <div className='bg-grey pb-5 text-center'>
    <div className='EVideo py-5'>
        <div className="container-lg">
            <div className="row px-lg-5 justify-content-center">
                <div className="col-xl-6 col-md-9 order-xl-1 order-2 pe-md-0">
                    <div className="EVideo__content px-xl-4 text-start pt-xl-0 pt-4">
                        <h1 className="EVideo__heading">
                        Accurate & efficient talent matching
                        </h1>
                        <p className="EVideo__content__detail">
                            Our AI, ML, and NLP work together to 
                            create and augment thorough job 
                            requirements by parsing the JD and 
                            criteria you provide us. Then our 
                            algorithms find only the best candidates 
                            matching your job requirements, saving 
                            you 50 sourcing hours per hire — 85% of 
                            candidates shortlisted for interviews 
                            are accepted by hiring teams.
                        </p>
                    </div>
                </div>
                <div className="col-xl-6 col-md-9 py-0 Job--col m-0 order-xl-2 order-1 px-xl-0">
                       <img src={employer} alt="" className='EVideo--img'/>
                </div>
            </div>
        </div>
    </div>
    <div className='d-flex flex-column align-items-center px-3'>
    <h4 className="EVideo__heading text-center mt-5">
    Learn more about how you can start securing key hires, faster.
    </h4>
    <button className='Form--form--button'><Link to={`/employer`} className="Form--form--button--Link text-decoration-none">Find candidates now</Link></button>
    </div>
    </div>
  )
}

export default EVideo