import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

const col1 =[
    {
        id: 1,
        title: 'Research Director'
    },
    {
        id: 2,
        title: 'Senior Research Scientist/Fellow'
    },
    {
        id: 3,
        title: 'Research Scientist/Fellow'
    },
    {
        id: 4,
        title: 'Postdoctoral Fellow'
    },
    {
        id: 5,
        title: 'Research Associate'
    },
    {
        id: 6,
        title: 'Research Assistant'
    },
    {
        id: 7,
        title: 'Doctoral Fellow'
    },
]
const col2 =[
    {
        id: 1,
        title: 'Professor'
    },
    {
        id: 2,
        title: ' Reader'
    },
    {
        id: 3,
        title: 'Senior Lecturer'
    },
    {
        id: 4,
        title: 'Dean'
    },
    {
        id: 5,
        title: 'Lecturer'
    },
    {
        id: 6,
        title: 'Assistant Professor'
    },
    {
        id: 7,
        title: 'Chair'
    },
    {
        id: 8,
        title: 'Associate Professor'
    },
    {
        id: 9,
        title: 'Director'
    },
    {
        id: 10,
        title: 'Head of Department'
    },
]
const col3 =[
    {
        id: 1,
        title: 'Data Engineer'
    },
    {
        id: 2,
        title: 'Data Scientist'
    },
    {
        id: 3,
        title: 'Data Analyst'
    },
    {
        id: 4,
        title: 'Data Architect'
    },
    {
        id: 5,
        title: 'Database Engineer'
    },
    {
        id: 6,
        title: 'Deep Learning Engineer'
    },
    {
        id: 7,
        title: 'Computer Vision Engineer'
    },
    {
        id: 8,
        title: 'Head of Research Engineering'
    },
    {
        id: 9,
        title: 'Head of Data Science'
    },
    {
        id: 10,
        title: 'Machine Learning Engineer'
    },
]
const col4 =[
    {
        id: 1,
        title: 'Laboratory Research Scientist'
    },
    {
        id: 2,
        title: 'Laboratory Research Manager'
    },
    {
        id: 3,
        title: 'Laboratory Technician'
    },
    {
        id: 4,
        title: 'Senior Microscopist'
    },
    {
        id: 5,
        title: 'Bioinformatician'
    },
    {
        id: 6,
        title: 'Senior Biostatistician'
    },
    {
        id: 7,
        title: 'Advanced Sequencing Manager'
    },
    {
        id: 8,
        title: 'MRI Lead'
    },
    {
        id: 9,
        title: 'Flow Cytometry Facility Manager'
    },
    {
        id: 10,
        title: 'Laboratory Technician'
    },
]    
function Candidates() {
  return (
    <div className='Candidates py-5'>
        <div className="container-md px-lg-1 px-4 py-5 d-flex flex-column align-items-center">
            <h1 className="Candidates__heading text-center mt-5">
            Get your dream job
            </h1>
            <p className="Candidates__detail text-center">
            No need to search and apply for positions. We find top fit jobs for you and connect you directly with a hiring manager. 
            </p>
            <button className='Candidates--button d-flex justify-content-center align-items-center mb-5'>
                <Link to={`/candidate_step1`} className='Candidates--button--Link text-decoration-none'>Get job offers </Link></button>
            <div className="row px-xl-5 px-lg-1 px-sm-5 mt-5">
                <div className="col-lg-4 col-md-6 d-flex justify-content-lg-start justify-content-md-end justify-content-center">
                    <div className="Candidates__card pt-4">
                        <div className="Candidates__card__content px-4 py-3">
                            <span className="Candidates__card__content__index">01</span>
                            <h6 className="Candidates__card__content__heading">Create a profile. <br />
                            Start matching. </h6>
                            <p className="Candidates__card__content__detail mt-3">
                            Tell us about your background and what you look for in a job. It takes a few steps, but it will make sure our matching algorithms find jobs that fit your skills and preferences. We’ve even got a CV parser to make things faster.
                            </p>
                            <ul className="Candidates__card__content--ul ps-3">
                                <li className="Candidates__card__content--ul--li ms-0">Showcase your skill set, passions, and career aspirations. </li>
                                <li className="Candidates__card__content--ul--li ms-0">We know you are more than your resume, so don’t be afraid to put yourself out there. Customize your profile with your favourite presentations, links to publication, or awards. </li>
                                <li className="Candidates__card__content--ul--li ms-0">Your profile is always hidden from past and current employers. </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex justify-content-md-start justify-content-center">
                    <div className="Candidates__card pt-4">
                        <div className="Candidates__card__content px-4 py-3">
                            <span className="Candidates__card__content__index">02</span>
                            <h6 className="Candidates__card__content__heading">Employers apply <br />
                            to you. </h6>
                            <p className="Candidates__card__content__detail mt-3">
                            Once you are approved, your profile will be promoted to employers for free. When our platform sees a potentially good fit, that employer will see you in their matches and can request an interview.
                            </p>
                            <ul className="Candidates__card__content--ul ps-3">
                                <li className="Candidates__card__content--ul--li ms-0">No applications, no cover letters. </li>
                                <li className="Candidates__card__content--ul--li ms-0">When companies reach out, they will come with a job description and salary up front. </li>
                                <li className="Candidates__card__content--ul--li ms-0">You’ll only hear from employers that match well with you. </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 d-flex justify-content-lg-start justify-content-md-end justify-content-center">
                    <div className="Candidates__card pt-4">
                        <div className="Candidates__card__content px-4 py-3">
                            <span className="Candidates__card__content__index">03</span>
                            <h6 className="Candidates__card__content__heading">Choose, interview, <br />
                            and accept offer. </h6>
                            <p className="Candidates__card__content__detail mt-3">
                            Compare interview requests side by side and say "yes" to the ones that best match your career goals and interests. Finally, say “yes” to the job you love the most!
                            </p>
                            <ul className="Candidates__card__content--ul ps-3">
                                <li className="Candidates__card__content--ul--li ms-0">Using proprietary data, rndmatch provides salary range to help guide you on how to best negotiate your offer.</li>
                                <li className="Candidates__card__content--ul--li ms-0">Compare offers side by side. </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <h1 className="Candidates__heading text-center pt-5 pb-md-5 pb-4">
                    Who is it for?
                </h1>
                <div className="col-xl-3 col-lg-4 col-md-6 d-flex justify-content-center mb-4">
                    <div className="Candidates__col text-start">
                    <div className='Candidates__col__heading'>
                    <h5 className="Cards__card__heading ">Researchers of all scientific fields</h5>
                    </div>
                    <ul className='Recruiting__card__para mt-3 list-unstyled d-flex justify-content-start flex-wrap text-start'>
                            {
                                col1.map((item)=>(
                                    <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>{item.title}</small></li>
                                ))
                            }
                            </ul>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4  col-md-6 d-flex justify-content-center mb-4">
                    <div className="Candidates__col text-start">
                    <div className='Candidates__col__heading'>
                    <h5 className="Cards__card__heading">Academic</h5>
                    </div>
                    <ul className='Recruiting__card__para mt-3 list-unstyled d-flex justify-content-start flex-wrap text-start'>
                            {
                                col2.map((item)=>(
                                    <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>{item.title}</small></li>
                                ))
                            }
                            </ul>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4  col-md-6 d-flex justify-content-center mb-4">
                    <div className="Candidates__col text-start">
                    <div className='Candidates__col__heading'>
                    <h5 className="Cards__card__heading ">Engineering and science computing</h5>
                    </div>
                    <ul className='Recruiting__card__para mt-3 list-unstyled d-flex justify-content-start flex-wrap text-start'>
                            {
                                col3.map((item)=>(
                                    <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>{item.title}</small></li>
                                ))
                            }
                            </ul>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 d-flex justify-content-center mb-4">
                    <div className="Candidates__col text-start">
                    <div className='Candidates__col__heading'>
                    <h5 className="Cards__card__heading ">Labortory</h5>
                    </div>
                    <ul className='Recruiting__card__para mt-3 list-unstyled d-flex justify-content-start flex-wrap text-start'>
                            {
                                col4.map((item)=>(
                                    <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>{item.title}</small></li>
                                ))
                            }
                            </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Candidates