import React from "react";
import "./style.css";
import { useState, useRef, useEffect} from "react";
import {CgClose} from 'react-icons/cg'
import logo from '../../Images/logo.png'
import { Link } from 'react-router-dom'

// const List1=[
//   {
//     id: 5,
//     ref: `/employers`,
//     text: 'Employers'
// },
// {
//     id: 6,
//     ref: `/candidates`,
//     text: 'Candidates'
// },
// {
//     id: 7,
//     ref: `/solution`,
//     text: 'Solution'
// },
// {
//     id: 8,
//     ref: `/pricing`,
//     text: 'Pricing'
// },
// {
//     id: 5,
//     ref: `/about`,
//     text: 'About'
// },
// {
//     id: 6,
//     ref: `/contact`,
//     text: 'Contact'
// }
// ]

function Sidebar( prop) {
  const [popup, setPopup] = useState(false);
  const data = useRef();
    const onclickOutside = (e) => {
      if(data.current.contains(e.target)) {
        // inside click
        console.log('clicked inside')
        return;
      } 
      // outside click
        console.log('clicked outside scope')
        setPopup(false);
    }
    useEffect(() => {
      document.addEventListener('mousedown', onclickOutside);
  
      // clean up function before running new effect
      return () => {
          document.removeEventListener('mousedown', onclickOutside);
      }
    }, [popup])

  return (
    <div className=" container-fluid position-absolute top-0">
      <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" 
      >
        <div className="offcanvas-header mt-2">
        <a href='/' className="Navbar__brand navbar-brand ms-4">
                <img src={logo} alt="Logo" width={140}/>
            </a>
          <CgClose className="btn-cross fs-2 text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        <div className="offcanvas-body">
        <div className="Navbar__right text-start">
            {prop.content}
            <div className="d-flex align-items-center flex-wrap">
            <button className='Navbar__right__btn Sidebar-login ms-4 mb-3' data-bs-dismiss="offcanvas" aria-label="Close">
            <Link to={`/login`} className='text-decoration-none pt-1 px-4 Sidebar__btn pb-1' >
                Login
            </Link>
            </button>
            <div className='Navbar__right__popup px-3 d-inline-block fs-sm-5 fs-6 ms-4 mb-3' onClick={(e)=>setPopup(current => !current)} ref={data}>
                Get Started
                <ul className="Navbar__right__popup__menu list-unstyled sidebar-popup" 
                style={{
                    transform: popup ? 'scale(1)' : 'scale(0)', 
                }}
                >
                    <li className="Navbar__right__popup__menu__item pb-2 pt-3 text-start" data-bs-dismiss="offcanvas" aria-label="Close">
                        <Link to={`/employer`} className="text-decoration-none Navbar__right__popup__menu__item__link px-4 fs-5">Employer</Link>
                    </li>
                    <li className="Navbar__right__popup__menu__item pt-2 pb-3 text-start" data-bs-dismiss="offcanvas" aria-label="Close">
                        <Link to={`/candidate_step1`} className="text-decoration-none Navbar__right__popup__menu__item__link px-4 fs-5" >Candidate </Link>
                    </li>
                </ul>
            </div>
            </div>
            </div>
        </div>
        </div>
      </div>
  );
}

export default Sidebar;
