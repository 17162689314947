import React from 'react';
import './style.css';
import video from '../../../Videos/About top.mp4'
import { Link } from 'react-router-dom';

function Banner() {
  return (
    <div className='Banner pb-md-5'>
          <div className="container-lg my-5 px-lg-0 px-sm-5 px-4">
          <div className="row d-flex flex-wrap-reverse justify-content-center align-items-center p-0 pt-5">
            <div className="col-lg-7 pe-0 d-flex justify-content-lg-start justify-content-center pt-lg-5 pt-5">
            <div className="Banner__left mb-0 ps-md-0 ps-2 pb-sm-0 pb-3">
          <h1 className="Banner__left__heading">
            Our mission
          </h1>
          <span className='Banner__left--p'>To connect the world's best talent with the most ambitious R&D businesses </span> <br />
          <span className='Banner__left--p mb-4'>to solve challenging problems and help build diverse teams of top performers.</span> <br />
          <button className='Banner__left--button mt-4 py-2 px-3'><Link to={`/solution`} className="text-decoration-none Banner__left--button--Link">See our solutions</Link></button>
            </div>
            </div>
                <div className="col-lg-5 ps-md-4 p-0 Job--col order-lg-2 order-1 m-0 pt-4 d-flex justify-content-lg-start justify-content-center">
                        <video loop autoPlay muted className='Job--video2 Banner--video'>
                            <source src={video} type="video/mp4" />
                        </video>
                </div>
          </div>
        </div>
    </div>
  )
}

export default Banner