import React from 'react'
import './style.css'
import check from '../../../Images/check-icon.png'

const content=[
    {
        id: 1,
        heading: "Find a better fit, faster",
        para: "Get matched with highly qualified candidates within a few seconds, not months and scale your recruiting faster.",
        class: 'card1'
    },
    {
        id: 2,
        heading: "Get to know candidates upfront ",
        para: "See their top skill sets, background, recommendations, salary expectations and work preferences upfront.",
        class: 'card2'
    },
    {
        id: 3,
        heading: "Interview best candidates only ",
        para: "Our algorithms find only the right candidates matching your job requirements, saving you a lot of time and money.",
        class: 'card3'
    }
]

function Cards() {
  return (
    <div className='Cards'>
        <div className="container-md Cards--container">
            <div className="row Cards--row">
                {content.map((item)=>(
                    <div className={`col-md-4 mb-md-5 ${item.class}`} key={item.id}>
                    <div className='Cards__content py-4 mb-md-0 mb-5 '>
                        <img src={check} alt="check-icon" />
                        <h5 className='Cards__content__heading mb-2 mt-3'>{item.heading}</h5>
                        <p className="Cards__content__para">
                        {item.para} 
                        </p>
                    </div>
                </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Cards