import React, {useState} from 'react'
import './style.css'
import {BsPlusLg} from 'react-icons/bs'
import {TbMinus} from 'react-icons/tb'

const card1 =[
    {
        id: 1,
        title: 'Professor'
    },
    {
        id: 2,
        title: ' Reader'
    },
    {
        id: 3,
        title: 'Senior Lecturer'
    },
    {
        id: 4,
        title: 'Associate Professor'
    },
    {
        id: 5,
        title: 'Lecturer'
    },
    {
        id: 6,
        title: 'Assistant Professor'
    },
    {
        id: 7,
        title: 'Chair'
    },
    {
        id: 8,
        title: 'Director'
    },
    {
        id: 9,
        title: 'Dean'
    },
    {
        id: 10,
        title: 'Head of Department'
    },
    {
        id: 11,
        title: 'Registrar /Assistant Registrar'
    },
]
const card2 =[
    {
        id: 1,
        title: 'Research Director'
    },
    {
        id: 2,
        title: 'Senior Research Scientist/Fellow'
    },
    {
        id: 3,
        title: 'Research Scientist/Fellow'
    },
    {
        id: 4,
        title: 'Postdoctoral Fellow'
    },
    {
        id: 5,
        title: 'Research Associate'
    },
    {
        id: 6,
        title: 'Research Assistant'
    },
    {
        id: 7,
        title: 'Doctoral Fellow'
    },
    
]
const card3 =[
    {
        id: 1,
        title: 'Research Manager'
    },
    {
        id: 2,
        title: 'Project Manager'
    },
    {
        id: 3,
        title: 'Collaboration Manager'
    },
    {
        id: 4,
        title: 'Programme Manager'
    },
    {
        id: 5,
        title: 'Science Writer'
    },
    {
        id: 6,
        title: 'Public Engagement Manager'
    },
    {
        id: 7,
        title: 'Strategic Partnership Manager'
    },
    {
        id: 8,
        title: 'Events and Engagement Manager'
    },
    {
        id: 9,
        title: 'Director of Communications and Engagement'
    },
    {
        id: 10,
        title: 'Head of Communications and Marketing'
    },
    {
        id: 11,
        title: 'Events and Marketing Manager'
    },
    {
        id: 12,
        title: 'Business Analyst'
    },
    {
        id: 13,
        title: 'Academic Engagement Manager'
    },
    {
        id: 14,
        title: 'Business Analyst'
    },
    {
        id: 15,
        title: 'Academic Engagement Manager'
    },
    {
        id: 16,
        title: 'Head of Partnerships/Collaboration'
    },
    {
        id: 17,
        title: 'Community Manager'
    },
    {
        id: 18,
        title: 'Research Application Manager'
    },
    {
        id: 19,
        title: 'Research Computing Manager'
    },
    {
        id: 20,
        title: 'Commercial Translation Manager'
    },
    {
        id: 17,
        title: 'Digital Product Manager'
    },
]
const card4 =[
    {
        id: 1,
        title: 'Site Reliability Engineer'
    },
    {
        id: 2,
        title: 'Security Engineer'
    },
    {
        id: 3,
        title: 'Network Engineer'
    },
    {
        id: 4,
        title: 'Automation Engineer'
    },
    {
        id: 5,
        title: 'System Administrator'
    },
    {
        id: 6,
        title: 'Head of Infrastructure'
    },
    {
        id: 7,
        title: 'Project Engineer'
    },
    {
        id: 8,
        title: 'Facilities Manager'
    },
    {
        id: 9,
        title: 'Engineering Projects Manager'
    },
    {
        id: 10,
        title: 'Service Partner Manager'
    },
    {
        id: 11,
        title: 'Technical Systems Engineer'
    },
    {
        id: 12,
        title: 'Logistics Operative'
    },
]
const card5 =[
    {
        id: 1,
        title: 'Data Engineer'
    },
    {
        id: 2,
        title: 'Data Scientist'
    },
    {
        id: 3,
        title: 'Data Analyst'
    },
    {
        id: 4,
        title: 'Data Architect'
    },
    {
        id: 5,
        title: 'Database Engineer'
    },
    {
        id: 6,
        title: 'Deep Learning Engineer'
    },
    {
        id: 7,
        title: 'Computer Vision Engineer'
    },
    {
        id: 8,
        title: 'Head of Research Engineering'
    },
    {
        id: 9,
        title: 'Head of Data Science'
    },
    {
        id: 10,
        title: 'Machine Learning Engineer'
    },
    {
        id: 11,
        title: 'Cloud Engineer'
    },
    {
        id: 12,
        title: 'Head of Engineering'
    },
    {
        id: 13,
        title: 'Engineering Manager'
    },
    {
        id: 14,
        title: 'Research Software Engineer'
    },
    {
        id: 15,
        title: 'Research Data Scientist'
    },
    {
        id: 16,
        title: 'Principal Data Scientist'
    },
    {
        id: 17,
        title: 'Big Data Systems Engineer'
    },
    {
        id: 18,
        title: 'Research Data Service Manager'
    },
    {
        id: 19,
        title: 'Data Platforms Engineer'
    },
]
const card6 =[
    {
        id: 1,
        title: 'IT Support Engineer'
    },
    {
        id: 2,
        title: 'Information Security Analyst'
    },
    {
        id: 3,
        title: 'IT Administrator'
    },
    {
        id: 4,
        title: 'Systems Engineer'
    },
    {
        id: 5,
        title: 'CIO'
    },
    {
        id: 6,
        title: 'Director of IT & Information Security'
    },
    {
        id: 7,
        title: 'CTO'
    },
    {
        id: 8,
        title: 'Full Stack Developer'
    },
    {
        id: 9,
        title: 'DevOps Engineer'
    },
    {
        id: 10,
        title: 'Integration Developer'
    },
    {
        id: 11,
        title: 'Technical Director'
    },
    {
        id: 12,
        title: 'CRM Developer'
    },
    {
        id: 13,
        title: 'Technical Architect'
    },
    {
        id: 14,
        title: 'IT Business Analyst'
    },
    {
        id: 15,
        title: 'Cloud/DevOps Architect'
    },
    {
        id: 16,
        title: 'Infrastructure Architect'
    },
    {
        id: 17,
        title: 'IT Operations Manager'
    },
    {
        id: 18,
        title: 'ServiceNow Manager'
    },
    {
        id: 19,
        title: 'Network Architect'
    },
    {
        id: 20,
        title: 'IT Project Manager'
    },
]
const card7 =[
    {
        id: 1,
        title: 'Commercial/IP Lawyer'
    },
    {
        id: 2,
        title: 'General Counsel'
    },
    {
        id: 3,
        title: 'Legal Counsel'
    },
    {
        id: 4,
        title: 'Paralegal'
    },
    {
        id: 5,
        title: 'Data & Privacy Advisor'
    },
    {
        id: 6,
        title: 'Contracts Manager'
    },
    {
        id: 7,
        title: 'Finance Business Partner'
    },
    {
        id: 8,
        title: 'Grant Manager'
    },
    {
        id: 9,
        title: 'HR Business Partner'
    },
    {
        id: 10,
        title: 'HR Advisor'
    },
    {
        id: 11,
        title: 'EDI Manager'
    },
    {
        id: 12,
        title: 'Head of HR'
    },
    {
        id: 13,
        title: 'Reward & Benefits Manager'
    },
    {
        id: 14,
        title: 'Payroll & Pensions Manager'
    },
    {
        id: 15,
        title: 'Procurement Manager'
    },
    {
        id: 16,
        title: 'HR Operations Manager'
    },
    {
        id: 17,
        title: 'Finance Planning & Analysis Manager'
    },
    {
        id: 18,
        title: 'Finance Systems Manager'
    },
    {
        id: 19,
        title: 'Risk & Control Manager'
    },
    {
        id: 20,
        title: 'Chief People Officer'
    },
    {
        id: 21,
        title: 'HR Systems Manager'
    },
    {
        id: 22,
        title: 'Recruitment Manager'
    },
]
const card8 =[
    {
        id: 1,
        title: 'Laboratory Research Scientist'
    },
    {
        id: 2,
        title: 'Laboratory Research Manager'
    },
    {
        id: 3,
        title: 'Laboratory Technician'
    },
    {
        id: 4,
        title: 'Senior Microscopist'
    },
    {
        id: 5,
        title: 'Bioinformatician'
    },
    {
        id: 6,
        title: 'Senior Biostatistician'
    },
    {
        id: 7,
        title: 'Advanced Sequencing Manager'
    },
    {
        id: 8,
        title: 'MRI Lead'
    },
    {
        id: 9,
        title: 'Flow Cytometry Facility Manager'
    },
    {
        id: 10,
        title: 'Laboratory Technician'
    },
    {
        id: 11,
        title: 'Laboratory Manager'
    },
    {
        id: 12,
        title: 'Laboratory Software Engineer'
    },
    {
        id: 13,
        title: 'Clinical Trial Laboratory Technician'
    },
]
function Cards() {
    const [visible1, setVisible1]= useState(false)
    const [visible2, setVisible2]= useState(false)
    const [visible3, setVisible3]= useState(false)
    const [visible4, setVisible4]= useState(false)
    const [visible5, setVisible5]= useState(false)
    const [visible6, setVisible6]= useState(false)
    const [visible7, setVisible7]= useState(false)
    const [visible8, setVisible8]= useState(false)
  return (
    <div className='Cards py-5'>
        <div className="container-md px-md-0 px-3 pt-5">
            <div className="row justify-content-center">
            <h1 className="Cards__heading text-center">
            Every R&D position, in one place
            </h1>
            <h1 className="Cards__heading Cards__heading-green text-center">
            Access top candidates without any friction
            </h1>
                <div className="Cards__container mt-4 d-flex flex-wrap justify-content-md-start justify-content-center">
                <div className="Cards__card py-3 px-4 mx-3 my-3">
                    <h3 className="Cards__card__heading">Academic</h3>
                    <div
                    style={{
                        display: visible1 ? 'none' : 'block', 
                    }}
                    >
                    <ul className='Recruiting__card__para mt-3 list-unstyled d-flex justify-content-center flex-wrap text-center'
                    
                    >
                            {
                                card1.map((item)=>(
                                    <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>{item.title}</small></li>
                                ))
                            }
                            </ul>
                    </div>
                    <BsPlusLg className='Cards__card__icon fs-3' onClick={(e)=>setVisible1(current => !current)}
                    style={{
                        display: visible1 ? 'block' : 'none',  
                    }}
                    />
                    <TbMinus className='Cards__card__icon Cards__card__icon-minus fs-1' onClick={(e)=>setVisible1(current => !current)}
                    style={{
                        display: visible1 ? 'none' : 'block',  
                    }}
                    />
                </div>
                <div className="Cards__card py-3 px-4 mx-3 my-3">
                    <h3 className="Cards__card__heading">Research</h3>
                    <div
                    style={{
                        display: visible2 ? 'block' : 'none', 
                    }}
                    >
                    <ul className='Recruiting__card__para mt-3 list-unstyled d-flex justify-content-center flex-wrap text-center'>
                            {
                                card2.map((item)=>(
                                    <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>{item.title}</small></li>
                                ))
                            }
                            </ul>
                    </div>
                    <BsPlusLg className='Cards__card__icon fs-3' onClick={(e)=>setVisible2(current => !current)}
                    style={{
                        display: visible2 ? 'none' : 'block',  
                    }}
                    />
                    <TbMinus className='Cards__card__icon Cards__card__icon-minus fs-1' onClick={(e)=>setVisible2(current => !current)}
                    style={{
                        display: visible2 ? 'block' : 'none',  
                    }}
                    />
                </div>
                <div className="Cards__card py-3 px-4 mx-3 my-3">
                    <h3 className="Cards__card__heading">Operations & Business</h3>
                    <div
                    style={{
                        display: visible3 ? 'block' : 'none', 
                    }}
                    >
                    <ul className='Recruiting__card__para mt-3 list-unstyled d-flex justify-content-center flex-wrap text-center'>
                            {
                                card3.map((item)=>(
                                    <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>{item.title}</small></li>
                                ))
                            }
                            </ul>
                    </div>
                    <BsPlusLg className='Cards__card__icon fs-3' onClick={(e)=>setVisible3(current => !current)}
                    style={{
                        display: visible3 ? 'none' : 'block',  
                    }}
                    />
                    <TbMinus className='Cards__card__icon Cards__card__icon-minus fs-1' onClick={(e)=>setVisible3(current => !current)}
                    style={{
                        display: visible3 ? 'block' : 'none',  
                    }}
                    />
                </div>

                <div className="Cards__card py-3 px-4 mx-3 my-3">
                    <h3 className="Cards__card__heading">Facilities & Infrastructure</h3>
                    <div
                    style={{
                        display: visible4 ? 'block' : 'none', 
                    }}
                    >
                    <ul className='Recruiting__card__para mt-3 list-unstyled d-flex justify-content-center flex-wrap text-center'>
                            {
                                card4.map((item)=>(
                                    <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>{item.title}</small></li>
                                ))
                            }
                            </ul>
                    </div>
                    <BsPlusLg className='Cards__card__icon fs-3' onClick={(e)=>setVisible4(current => !current)}
                    style={{
                        display: visible4 ? 'none' : 'block',  
                    }}
                    />
                    <TbMinus className='Cards__card__icon Cards__card__icon-minus fs-1' onClick={(e)=>setVisible4(current => !current)}
                    style={{
                        display: visible4 ? 'block' : 'none',  
                    }}
                    />
                </div>
                <div className="Cards__card py-3 px-4 mx-3 my-3">
                    <h3 className="Cards__card__heading">Engineering & Science Computing</h3>
                    <div
                    style={{
                        display: visible5 ? 'block' : 'none', 
                    }}
                    >
                    <ul className='Recruiting__card__para mt-3 list-unstyled d-flex justify-content-center flex-wrap text-center'>
                            {
                                card5.map((item)=>(
                                    <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>{item.title}</small></li>
                                ))
                            }
                            </ul>
                    </div>
                    <BsPlusLg className='Cards__card__icon fs-3' onClick={(e)=>setVisible5(current => !current)}
                    style={{
                        display: visible5 ? 'none' : 'block',  
                    }}
                    />
                    <TbMinus className='Cards__card__icon Cards__card__icon-minus fs-1' onClick={(e)=>setVisible5(current => !current)}
                    style={{
                        display: visible5 ? 'block' : 'none',  
                    }}
                    />
                </div>

                <div className="Cards__card py-3 px-4 mx-3 my-3">
                    <h3 className="Cards__card__heading">IT Services</h3>
                    <div
                    style={{
                        display: visible6 ? 'block' : 'none', 
                    }}
                    >
                    <ul className='Recruiting__card__para mt-3 list-unstyled d-flex justify-content-center flex-wrap text-center'>
                            {
                                card6.map((item)=>(
                                    <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>{item.title}</small></li>
                                ))
                            }
                            </ul>
                    </div>
                    <BsPlusLg className='Cards__card__icon fs-3' onClick={(e)=>setVisible6(current => !current)}
                    style={{
                        display: visible6 ? 'none' : 'block',  
                    }}
                    />
                    <TbMinus className='Cards__card__icon Cards__card__icon-minus fs-1' onClick={(e)=>setVisible6(current => !current)}
                    style={{
                        display: visible6 ? 'block' : 'none',  
                    }}
                    />
                </div>
                <div className="Cards__card py-3 px-4 mx-3 my-3">
                    <h3 className="Cards__card__heading">Legal, Finance & HR</h3>
                    <div
                    style={{
                        display: visible7 ? 'block' : 'none', 
                    }}
                    >
                    <ul className='Recruiting__card__para mt-3 list-unstyled d-flex justify-content-center flex-wrap text-center'>
                            {
                                card7.map((item)=>(
                                    <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>{item.title}</small></li>
                                ))
                            }
                            </ul>
                    </div>
                    <BsPlusLg className='Cards__card__icon fs-3' onClick={(e)=>setVisible7(current => !current)}
                    style={{
                        display: visible7 ? 'none' : 'block',  
                    }}
                    />
                    <TbMinus className='Cards__card__icon Cards__card__icon-minus fs-1' onClick={(e)=>setVisible7(current => !current)}
                    style={{
                        display: visible7 ? 'block' : 'none',  
                    }}
                    />
                </div>
                <div className="Cards__card py-3 px-4 mx-3 my-3">
                    <h3 className="Cards__card__heading">Laboratory</h3>
                    <div
                    style={{
                        display: visible8 ? 'block' : 'none', 
                    }}
                    >
                    <ul className='Recruiting__card__para mt-3 list-unstyled d-flex justify-content-center flex-wrap text-center'>
                            {
                                card8.map((item)=>(
                                    <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>{item.title}</small></li>
                                ))
                            }
                            </ul>
                    </div>
                    <BsPlusLg className='Cards__card__icon fs-3' onClick={(e)=>setVisible8(current => !current)}
                    style={{
                        display: visible8 ? 'none' : 'block',  
                    }}
                    />
                    <TbMinus className='Cards__card__icon Cards__card__icon-minus fs-1' onClick={(e)=>setVisible8(current => !current)}
                    style={{
                        display: visible8 ? 'block' : 'none',  
                    }}
                    />
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Cards