import React from 'react'
import './style.css'
import Ehero from '../..//Images/SignIn/sign-in hero.png';
import Top from '../../Images/SignIn/sign-in top.png'
import Bottom from '../../Images/SignIn/sign-in bottom.png'
import Logo from '../../Images/SignIn/sign-in logo.png'
import google from '../../Images/SignIn/sign-in google.png'
import { Link } from 'react-router-dom'


function Login() {
  return (
    <div className='Login pb-5'>
        <div className="container-sm py-5">
            <div className="Login__form mt-4 mb-5 mx-3">
                <div className="row">
                <div className="col-md-6">
                    <div className="Login__form__left w-100 h-100 d-flex py-5 px-4 text-center flex-column justify-content-center align-items-center">
                        <img src={Top} alt="" className='Login__form__left__top'/>
                        <img src={Ehero} alt="" className='Login__form__left__hero'/>
                        <h1 className="Login__form__left__heading">Welcome to</h1>
                        <img src={Logo} alt="" className='Login__form__left__logo'/>
                        <img src={Bottom} alt="" className='Login__form__left__bottom'/>
                    </div>
                </div>
                    <div className="col-md-6">
                        <div className="Login__form__right pt-5 pb-5 ps-sm-3 pe-sm-3 d-flex flex-column align-items-center">
                            <h1 className="Login__form__right__heading mb-md-5 mb-4">
                                <span className='Login__form__right__heading--secondary'>Sign</span>
                                <span className='Login__form__right__heading--main'> In</span>
                            </h1>
                            <form action="/" className='Login__form__right--form pt-2 text-center pe-lg-0 '>
                                <input type="email" placeholder='Email' className='Login__form__right--form--input px-3 py-2 w-100'/> <br />
                                <input type="password" placeholder='Password' className='Login__form__right--form--input px-3 py-2 mt-4'/> <br />
                                <div className='d-sm-flex justify-content-between text-start px-2 mt-2'>
                                   <div>
                                   <input type="checkbox" name="passwordcheck" id="password" className='Login__form__right--form--checkbox me-2' />
                                   <label htmlFor="password" className='Login__form__right--form--label'><small> Keep me signed in</small></label>
                                   </div>
                                   <a href="/" className='text-decoration-none Login__form__right--form--small ms-sm-0'><small className=' Login__form__right--form--small'>Forgot Password?</small></a>
                                </div>
                                <button type='submit' className='Login__form__right--form--button w-100 my-3 py-2'>Sign in</button>
                                <div className='Login__form__right--form__divider d-flex align-items-center justify-content-center'> 
                                   <span className='mx-2 Login__form__right--form__divider--small'>OR</span>
                                </div>
                                <button type='submit' className='Login__form__right--form--button2 w-100 my-3 py-2'>
                                    <img src={google} alt="" className="me-3 Login__form__right--form--button2--img"/>
                                    Sign with Google</button>
                                    <span className='Login__form__right--form__para'>Not on rndmatch yet? </span> 
                                    <Link to={`/candidate_step1`} className='Login__form__right--form__para--Link text-decoration-none d-sm-inline d-block'>Get started</Link>
                                    <span className='d-flex align-items-center justify-content-center mb-2'>__</span>
                                    <span className='Login__form__right--form__para d-block'>If you want to join as a company, you can</span>
                                    <Link to={`/employer`} className='Login__form__right--form__para--Link text-decoration-none d-block'>Sign up here</Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Login