import React from 'react'
import './style.css'
import recruit1 from '../../../Images/Recruiting/Recruit 1.png'
import recruit2 from '../../../Images/Recruiting/Recruit 2.png'
import recruit3 from '../../../Images/Recruiting/Recruit 3.png'
import recruit4 from '../../../Images/Recruiting/Recruit 4.png'
import recruit5 from '../../../Images/Recruiting/Recruit 5.png'

const title =[
    {
        id: 11,
        title: 'Higher Education'
    },
    {
        id: 22,
        title: 'Scientific Institutes'
    },
    {
        id: 33,
        title: 'Pharmaceuticals'
    },
    {
        id: 44,
        title: 'Aerospace'
    },
    {
        id: 55,
        title: 'AI Research'
    },
    {
        id: 66,
        title: 'Automotive Research'
    },
    {
        id: 77,
        title: 'Research Software Engineering'
    },
]

function Recruiting() {
  return (
    <div className='Recruiting'>
        <div className="container-md pt-md-5 pb-sm-5 pb-3">
            <h3 className='Recruiting__heading mb-4'><span className='Recruiting__heading--green'>Candidate-focused recruiting</span> – Fill roles faster with qualified candidates!</h3>
            <div className="row">
                <div className="col-lg-4 mb-4">
                    <div className="Recruiting__card pt-4 pb-2">
                        <div className='d-flex'>
                        <img src={recruit1} alt="recruit1" />
                        <h5 className='fw-bold Recruiting__card__heading ps-2 my-auto'>Researchers</h5>
                        </div>
                        <p className='Recruiting__card__para mt-3'>Experienced research scientists and engineers, postdocs, and graduating PhD candidates with expertise across 1000+ research fields.</p>
                    </div>
                </div>
                <div className="col-lg-4 mb-4">
                    <div className="Recruiting__card pt-4 pb-2">
                    <div className='d-flex'>
                        <img src={recruit2} alt="recruit1" />
                        <h5 className=' fw-bold Recruiting__card__heading my-auto ps-2'>Technicians</h5>
                        </div>
                        <p className='Recruiting__card__para mt-3'>Expert laboratory technicians, technical systems engineers, software engineers as well as candidates from all science technical areas.</p>
                    </div>
                </div>
                <div className="col-lg-4 mb-4">
                    <div className="Recruiting__card Recruiting__card--3 pt-4 pb-2">
                    <div className='d-flex'>
                        <img src={recruit3} alt="recruit1" />
                        <h5 className=' fw-bold Recruiting__card__heading my-auto ps-3'>R&D Support Staff</h5>
                        </div>
                        <p className='Recruiting__card__para mt-3'>Strategic partnership manager, R&D manager, programme/project manager and grants manager with industry-specific knowledge.</p>
                    </div>
                </div>
                <div className="col-lg-8 mb-4 mt-lg-3">
                    <div className="Recruiting__card pt-4 pb-2 ">
                    <div className='d-flex'>
                        <img src={recruit4} alt="recruit1" />
                        <h5 className=' fw-bold Recruiting__card__heading my-auto ps-3'>Core industries & product focus</h5>
                        </div>
                        <ul className='Recruiting__card__para mt-3 list-unstyled d-flex justify-content-center flex-wrap text-center'>
                            {
                                title.map((item)=>(
                                    <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2' key={item.id}><small>{item.title}</small></li>
                                ))
                            }
                            </ul>
                            <p className=' Recruiting__card__para text-center mt-0'>and many more…</p>
                    </div>
                </div>
                <div className="col-lg-4 mb-4 mt-lg-3">
                    <div className="Recruiting__card pt-4 Recruiting__card--5">
                    <div className='d-flex'>
                        <img src={recruit5} alt="recruit1" />
                        <h5 className=' fw-bold Recruiting__card__heading my-auto ps-3'>All Locations</h5>
                        </div>
                        <ul className='Recruiting__card__para mt-3 list-unstyled d-flex flex-wrap text-center'>
                            <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>Onsite</small></li>
                            <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>hybrid</small></li>
                            <li className='Recruiting__card__para__title py-2 px-3 mx-1 mb-2'><small>remote</small></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Recruiting