import React from 'react'
import video from '../../../Videos/Find your dream job.mp4'
import './style.css'

function Video() {
  return (
    <div className='Video'>
        <div className="container-md pt-md-5 pb-md-5 pb-sm-2 pb-0">
            <div className="Video__container mx-auto my-auto">
            <video loop autoPlay muted className='Video__container--video'>
                <source src={video} type="video/mp4" />
            </video>
            </div>
        </div>
    </div>
  )
}

export default Video