import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'
// import Img from 'react-optimized-image';

function SignUp(prop) { 
  return (
    <div className='Signup my-5 py-5'>
        <div className="container-md py-5">
            <div className="row">
                <div className="col-lg-5 col-md-6 px-md-2 px-4 image-col">
                    <div className="Signup__left h-100 d-flex justify-content-md-end justify-content-center align-items-lg-start align-items-center">
                        <img src={prop.image} alt="" className='Signup__left--img'/>
                    </div>
                </div>
                <div className="col-lg-7 col-md-6">
                    <div className="Signup__right h-100 pt-2 pb-5  px-lg-4 px-md-0 px-4">
                        <h1 className="Signup__right__heading text-md-start text-sm-center text-start">Start using rndmatch</h1>
                        <p className='Signup__right__para text-md-start text-sm-center text-start'>No long signups. 5 simple questions and you’re in</p>    
                        {prop.content}
                        <p className="Signup__right__bottom">Already Have an account ? <Link to={`/login`} className="text-decoration-none Signup__right__bottom--Link">Log in</Link> </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SignUp