import React from 'react'
import { Link } from 'react-router-dom'

function AcceptableUse() {
  return (
    <div className='Policy d-flex justify-content-center px-4'>
            <div className="Policy__container pb-5 pt-4">
                <h6 className="Policy__container--h6 text-uppercase">
                WHAT IS ACCEPTABLE USE POLICY?
                </h6>
                <p className='Policy__container__para'>
                This policy sets out the terms between you and us under which you may access our site and webapp at www. rndmatch.com (our site).
                </p>
                <p className='Policy__container__para'>The policy applies to all users of our site.</p>
                <p className='Policy__container__para'>By using our site you accept and agree to abide by all the terms and policies in this acceptable use policy. This policy (and all the policies referenced within it) supplement our terms and conditions. Our terms and conditions of use are available <Link to={'/terms'} className='Policy__container__para--Link'>here</Link>.</p>
                <h6 className="Policy__container--h6 text-uppercase">
                WHO ARE WE?
                </h6>
                <p className='Policy__container__para'>We are rndmatch Limited (we or us). We operate the website and web app available at www.rndmatch.com.</p>
                <p className='Policy__container__para'>We are registered in England and Wales under company number 13901214. We have our registered office at 5 Briskman Way, Aylesbury HP21 8FP, United Kingdom.</p>
                <h6 className="Policy__container--h6 text-uppercase">
                USES OF OUR SITE WHICH ARE PROHIBITED
                </h6>
                <p className='Policy__container__para'>You may use our site only for lawful purposes.</p>
                <p className='Policy__container__para'>You may not use our site in any of the following ways:</p>
                <ul className='Policy__container__list'>
                    <li className="Policy__container__list__item mb-2">To use any data mining, robots or similar data gathering or extraction methods.</li>
                    <li className="Policy__container__list__item mb-2">In any way that breaches any applicable local, national or international law or regulation.</li>
                    <li className="Policy__container__list__item mb-2">Violate or attempt to violate the security of our site including attempting to probe, scan or test the vulnerability of a system or network.</li>
                    <li className="Policy__container__list__item mb-2">Attempt to breach security or authentication measures.</li>
                    <li className="Policy__container__list__item mb-2">To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
                    <li className="Policy__container__list__item mb-2">In any way that is either fraudulent or unlawful, or has any fraudulent or unlawful purpose or effect.</li>
                    <li className="Policy__container__list__item mb-2">To harm or attempt to harm minors in any way.</li>
                    <li className="Policy__container__list__item mb-2">Aggregate, copy or duplicate in any manner any of the information on the site other than as permitted by the terms and conditions.</li>
                    <li className="Policy__container__list__item mb-2">To upload, download, send, knowingly receive, use or re-use any material which does not comply with the content standards set out below.</li>
                    <li className="Policy__container__list__item mb-2">To procure the sending of, or transmit, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation.</li>
                </ul>
                <p className='Policy__container__para'>By using our site, you agree:</p>
                <ul className='Policy__container__list'>
                    <li className="Policy__container__list__item mb-2">Not to reproduce, duplicate, copy or re-sell any part of our site in contravention of the provisions of our terms and conditions of use. Our terms and conditions of use are available <Link to={'/terms'} className='Policy__container__para--Link'>here</Link>.</li>
                    <li className="Policy__container__list__item mb-2">To use this site only if you are either seeking employment and/or career information or if, if you are a prospective employer, and employer seeking employees.</li>
                    <li className="Policy__container__list__item mb-2">Not to damage, disrupt, access without authority, or interfere with:
                    <ul className='mt-2'>
                    <li className="Policy__container__list__item mb-1">any part of our site;</li>
                    <li className="Policy__container__list__item">any software used in the provision of our site;</li>
                    <li className="Policy__container__list__item">any equipment or network on which our site is stored; or</li>
                    <li className="Policy__container__list__item">any equipment or network or software owned or used by any third party.</li>
                    </ul>
                    </li>
                </ul>
                <h6 className="Policy__container--h6 text-uppercase">
                CONTENT STANDARDS
                </h6>
                <p className='Policy__container__para'>These content standards apply to all material which you contribute to our site (Your Content).</p>
                <p className='Policy__container__para'>Your Content must:</p>
                <ul className='Policy__container__list'>
                    <li className="Policy__container__list__item mb-2">Contain facts only if they accurate.</li>
                    <li className="Policy__container__list__item mb-2">Contain opinions only if they are genuinely held.</li>
                    <li className="Policy__container__list__item mb-2">Comply with applicable law in the United Kingdom (and in any country from which they are posted).</li>
                </ul>
                <p className='Policy__container__para'>Your Content must not:</p>
                <ul className='Policy__container__list'>
                    <li className="Policy__container__list__item mb-2">Contain any material which is defamatory of any person.</li>
                    <li className="Policy__container__list__item mb-2">Be threatening, abusive or invasive of another’s privacy, or cause annoyance, inconvenience or needless anxiety.</li>
                    <li className="Policy__container__list__item mb-2">Contain any content that contains restricted or password-only access pages, images, hidden pages.</li>
                    <li className="Policy__container__list__item mb-2">Contain any material which is obscene, offensive, hateful or inflammatory.</li>
                    <li className="Policy__container__list__item mb-2">Promote material that is sexually explicit.</li>
                    <li className="Policy__container__list__item mb-2">Promotes violent material or violence.</li>
                    <li className="Policy__container__list__item mb-2">Promote any kind of discrimination relating to race, sex, religion, nationality, disability, sexual orientation or age.</li>
                    <li className="Policy__container__list__item mb-2">Infringe any copyright, database right or trade mark or any other intellectual property rights of any other person or entity.</li>
                    <li className="Policy__container__list__item mb-2">Be likely to deceive any person or entity.</li>
                    <li className="Policy__container__list__item mb-2">Be made in breach of any legal duty (including but not limited to a contractual duty or a duty of confidence) owed to a third party.</li>
                    <li className="Policy__container__list__item mb-2">Promote any activity which does not comply with applicable law in the United Kingdom (and in any country from which you make the contribution).</li>
                    <li className="Policy__container__list__item mb-2">Be likely or intended to harass, upset, embarrass, alarm or annoy any other person.</li>
                    <li className="Policy__container__list__item mb-2">Be used to impersonate any person or entity, or to misrepresent your identity or affiliation with any entity or person.</li>
                    <li className="Policy__container__list__item mb-2">Give the impression that it emanates from us if such contribution does not emanate from us.</li>
                    <li className="Policy__container__list__item mb-2">Advocate, promote or assist any unlawful act.</li>
                </ul>
                <p className='Policy__container__para'>The content standard set out above apply to each and every part of any contribution as well as to its whole.</p>
                <h6 className="Policy__container--h6 text-uppercase">
                IF YOU BREACH THE TERMS OF THIS POLICY YOUR ACCESS TO OUR SITE MAY BE SUSPENDED OR TERMINATED
                </h6>
                <p className='Policy__container__para'>In the event that we determine, in our reasonable opinion, that a breach of this policy has occurred as a result of your use of our site, we may suspend or terminate your access to our site.</p>
                <p className='Policy__container__para'>Failure to comply with this acceptable use policy constitutes a material breach of the terms and conditions of use (which are available <Link to={'/terms'} className='Policy__container__para--Link'>here</Link>).</p>
                <p className='Policy__container__para'>Such material breach may result in action against you, including but not limited to:</p>
                <ul className='Policy__container__list'>
                    <li className="Policy__container__list__item mb-2">Temporary, immediate or permanent withdrawal of your right to use our site.</li>
                    <li className="Policy__container__list__item mb-2">Legal action against you, including but not limited to legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not to, reasonable administrative and legal costs) resulting from the breach.</li>
                    <li className="Policy__container__list__item mb-2">Disclosure of such information to law enforcement authorities as is necessary (in our reasonable opinion).</li>
                    <li className="Policy__container__list__item mb-2">Temporary, immediate, or permanent removal of any posting or material uploaded by you to our site.</li>
                    <li className="Policy__container__list__item mb-2">Issuance of a warning to you.</li>
                </ul>
                <p className='Policy__container__para'>We exclude liability for actions taken in response to breaches of this acceptable use policy.</p>
                <h6 className="Policy__container--h6 text-uppercase">
                WE MAY MAKE CHANGES TO THIS POLICY
                </h6>
                <p className='Policy__container__para'>We may revise this acceptable use policy at any time. If we make changes to this policy we will do so by amending this page. You are expected to check this page from time to time to take notice of any changes we make, as they are legally binding on you.</p>

        </div>
    </div>
  )
}

export default AcceptableUse