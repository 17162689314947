import React from 'react'
import './style.css'
import solution5 from '../../../Images/Solution/Solution 5.png'
import solution6 from '../../../Images/Solution/Solution 6.png'
import solution7 from '../../../Images/Solution/Solution 7.png'
import solution8 from '../../../Images/Solution/Solution 8.png'
import solution9 from '../../../Images/Solution/Solution 9.png'
import solution10 from '../../../Images/Solution/Solution 10.png'
import solution11 from '../../../Images/Solution/Solution 11.png'
import solution12 from '../../../Images/Solution/Solution 12.png'


const cards2=[
    {
      id:1, 
      img: solution5,
      heading: 'CV Parsing',
      detail: 'Candidate can upload a CV to quickly fill in the information for their profile. Unstructured CVs are converted into structured and enriched data which can be matched with jobs.'
    },
    {
      id:2, 
      img: solution6,
      heading: 'Embedded skills API ',
      detail: 'Our candidate database is enriched with skills tags based on the information our embedded Skills API auto extracted from candidates’ CVs, recommendations, etc. Hiring managers are enabled to understand the actual skillset of candidates they are matched with upfront.'
    },
    {
      id:3, 
      img: solution7,
      heading: 'Job Parsing',
      detail: 'Unstructured data from the job descriptions are converted into a structured and enriched format. Once it gets the output, it then uses it against candidate profiles to find better matches.'
    },
    {
      id:4, 
      img: solution8,
      heading: 'Access untapped candidate ',
      detail: 'Our candidate database is built through candidate signup and AI scanning across multiple external databases, e.g., academia, personal websites, and a wide range of professional networks. Its reach extends past other sourcing solutions; it enables you to be the first to contact hard-to-find talent.'
    },
    {
      id:5, 
      img: solution9,
      heading: 'Automated shortlisting ',
      detail: 'Shortlisting can be a labour-intensive, time-consuming process. It can cost you money and good hires when candidates are hired faster by another employer. Our system can help generate shortlists, allowing hiring managers to quickly decide which candidates are best based on their knowledge and experience.'
    },
    {
      id:6, 
      img: solution10,
      heading: 'Video Interviewing',
      detail: 'Our platform integrates two-way video interviewing to improve your screening process, reduce the costs of filling key positions and increase hire quality.'
    },
    {
      id:7, 
      img: solution11,
      heading: 'Background screening  ',
      detail: 'Get pre-employment screening done in days, not weeks. With in-house solution, we make it fast and easy to run background checks you can trust.'
    },
    {
      id:8, 
      img: solution12,
      heading: 'Reporting & Analytics',
      detail: `If you can't measure it, you can't improve it. Track and improve your hiring process with real-time recruiting analytics. From standard, automated reports to custom, enterprise-grade business intelligence.`
    },
  ]
function Cards2() {
  return (
    <div className='Cards2 pb-5 pt-sm-5 pt-2'>
        <div className="container-md px-sm-5 px-4 d-flex justify-content-center pt-md-5 pt-sm-4">
            <div className="Cards2__container d-flex flex-wrap Content__text justify-content-start">
              <div className="Cards2__container__card Cards2__text pe-4 pt-4 my-3 me-xl-4 mx-3 d-flex align-items-sm-center align-items-end">
                 <h1 className="Cards2__text__heading">
                 Key Features <span className='Cards2__text__heading--green'>Hiring teams love our AI technology</span>
                 </h1>
              </div>
            {cards2.map((item)=>(
                  <div className="Cards2__container__card px-4 pb-4 pt-4 my-3 d-flex flex-column align-items-center me-xl-4 mx-3 text-center" key={item.id}>
                  <img src={item.img} alt=""  className='Cards2__container__card--img'/>
                  <h5 className="Cards2__container__card__heading text-center mt-4">{item.heading}</h5>
                  <p className='Cards2__container__card__detail'>{item.detail}</p>
                </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Cards2