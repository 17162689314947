import React, { useState, useRef, useEffect} from "react";
import "./style.css";
import {HiOutlineCheck} from 'react-icons/hi'
import {IoIosArrowDown} from 'react-icons/io'

const card1List= [
  {
    id: 1,
    text: 'Unlimited matching profiles'
  },
  {
    id: 2,
    text: 'Unlimited shortlisting '
  },
  {
    id: 3,
    text: '2 active positions'
  },
  {
    id: 4,
    text: '2 hiring managers'
  },
  {
    id: 5,
    text: '2 additional user seats'
  },
  {
    id: 6,
    text: 'Interview scheduler'
  },
  {
    id: 7,
    text: 'Video interview'
  },
  {
    id: 8,
    text: 'Dedicated support'
  },
]
const card2List= [
  {
    id: 1,
    text: 'Unlimited matching profiles'
  },
  {
    id: 2,
    text: 'Unlimited shortlisting '
  },
  {
    id: 3,
    text: '5 active positions'
  },
  {
    id: 4,
    text: '5 hiring managers'
  },
  {
    id: 5,
    text: '5 additional user seats'
  },
  {
    id: 6,
    text: 'Interview scheduler'
  },
  {
    id: 7,
    text: 'Video interview'
  },
  {
    id: 8,
    text: 'Dedicated support'
  },
  {
    id: 9,
    text: 'Team analytics'
  },
]
const card3List= [
  {
    id: 1,
    text: 'Unlimited matching profiles'
  },
  {
    id: 2,
    text: 'Unlimited shortlisting '
  },
  {
    id: 3,
    text: '10 active positions'
  },
  {
    id: 4,
    text: '5 hiring managers'
  },
  {
    id: 5,
    text: '5 additional user seats'
  },
  {
    id: 6,
    text: 'Interview scheduler'
  },
  {
    id: 7,
    text: 'Video interview'
  },
  {
    id: 8,
    text: 'Dedicated CS manager'
  },
  {
    id: 9,
    text: 'Advanced team analytics'
  },
]
const card4List= [
  {
    id: 1,
    text: 'Unlimited matching profiles'
  },
  {
    id: 2,
    text: 'Unlimited shortlisting '
  },
  {
    id: 3,
    text: 'Unlimited active positions'
  },
  {
    id: 4,
    text: 'Unlimited hiring managers'
  },
  {
    id: 5,
    text: 'Unlimited user seats'
  },
  {
    id: 6,
    text: 'Unlimited background checks'
  },
  {
    id: 7,
    text: 'Video interview'
  },
  {
    id: 8,
    text: 'Dedicated CS manager'
  },
  {
    id: 9,
    text: 'Advanced team analytics'
  },
  {
    id: 10,
    text: 'Invoice billing'
  },
  {
    id: 11,
    text: 'Custom Service-Level Agreement'
  },
]
const card5List= [
  {
    id: 1,
    text: 'Unlimited matching profiles'
  },
  {
    id: 2,
    text: 'Unlimited shortlisting '
  },
  {
    id: 3,
    text: '1 active position'
  },
  {
    id: 4,
    text: '1 hiring manager'
  },
  {
    id: 5,
    text: '1 user seats'
  },
  {
    id: 6,
    text: 'Interview scheduler'
  },
  {
    id: 7,
    text: 'Video interview'
  },
  {
    id: 8,
    text: 'Dedicated support'
  },
  {
    id: 9,
    text: 'Team analytics'
  },
  {
    id: 10,
    text: 'Invoice billing'
  },
]

function Pricing() {
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [priceclick, setGbpclick] = useState(true);
  const [eurclick, setEurclick] = useState(false);
  const [usdclick, setUsdclick] = useState(false);
  const [unit, setUnit] = useState('GBP');
  const [month, setMonth] = useState("/month");
  const [price, setPrice] = useState(['£960','£1750','£3000']);
  const [talent, setTalent] = useState('£850');
  const [corruncy, setCorruncy] = useState('£50');
  const node = useRef();
  const clickOutside = (e) => {
    if(node.current.contains(e.target)) {
      // inside click
      console.log('clicked inside')
      return;
    } 
    // outside click
      console.log('clicked outside scope')
      setOpen(false);
  }
  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);

    // clean up function before running new effect
    return () => {
        document.removeEventListener('mousedown', clickOutside);
    }
  }, [open])
  function monthly(e){
    setClick(false)
    setMonth('/month')
    if(price[0]=== '£9600'){
      setPrice(['£960','£1750','£3000'])
    }
    else if(price[0]=== '€11600'){
      setPrice(['€1160','€1900','€3250'])
    }
    else if(price[0]=== '$12600'){
      setPrice(['$1260','$2000','$3400'])
    }
  }
  function annual(e){
    setClick(true)
    setMonth('/year')
    if(price[0]=== '£960'){
      setPrice(['£9600','£17500','£30000'])
    }
    else if(price[0]=== '€1160'){
      setPrice(['€11600','€19000','€32500'])
    }
    else if(price[0]=== '$1260'){
      setPrice(['$12600','$20000','$34000'])
    }
  }
  function GBP(e){
    setGbpclick(true)
    setEurclick(false)
    setUsdclick(false)
    setCorruncy('£50')
    setTalent("£850")
    setUnit('GBP')
    if(price[0]=== '£960' || price[0]=== '€1160' || price[0]=== '$1260'){
      setPrice(['£960','£1750','£3000'])
    }
    else if(price[0]=== '£9600' || price[0]=== '€11600' || price[0]=== '$12600'){
      setPrice(['£9600','£17500','£30000'])
    }
  }
  function EUR(e){
    setGbpclick(false)
    setEurclick(true)
    setUsdclick(false)
    setCorruncy('€55')
    setTalent("€750")
    setUnit('EUR')
    if(price[0]=== '£960' || price[0]=== '€1160' || price[0]=== '$1260'){
      setPrice(['€1160','€1900','€3250'])
    }
    else if(price[0]=== '£9600' || price[0]=== '€11600' || price[0]=== '$12600'){
      setPrice(['€11600','€19000','€32500'])
    }
  }
  function USD(e){
    setGbpclick(false)
    setEurclick(false)
    setUsdclick(true)
    setCorruncy('$60')
    setTalent("$850")
    setUnit('USD')
    if(price[0]=== '£960' || price[0]=== '€1160' || price[0]=== '$1260'){
      setPrice(['$1260','$2000','$3400'])
    }
    else if(price[0]=== '£9600' || price[0]=== '€11600' || price[0]=== '$12600'){
      setPrice(['$12600','$20000','$34000'])
    }
  }
  return (
    <div className="Pricing py-5">
      <div className="container-xl d-flex flex-column align-items-center mt-5 px-xl-0 px-4 pt-2">
        <div className="d-flex flex-column align-items-center">
        <h1 className="Pricing__heading text-center mt-5">
          Pricing that scales as you do
        </h1>
        <div class="btn-group btn-group-lg Pricing__group my-3" role="group" aria-label="First group">
          <button type="button" class="btn Pricing__group__btn Pricing__group__btn1 text-center" onClick={monthly}
          style={{
            background: click ? '#fff' : '#356545', 
            color:  click ? '#222' : '#fff'
        }}
          >
            Monthly
          </button>
          <button type="button" class="btn Pricing__btn Pricing__group__btn2" onClick={annual}
          style={{
            background: click ? '#356545' : '#fff', 
            color:  click ? '#fff' : '#222'
        }}
          >
            Annual <small className="text-uppercase Pricing__group__btn--small">2 Months Free</small>
          </button>
        </div>
        <p className="Pricing__detail text-center mt-2">
        For hiring teams who want flexibility. No hiring fees. Cancel anytime.
        </p>
        </div>
        <div className="d-flex flex-wrap pt-5 Pricing-container justify-content-center">
          <div name="unit" id="unit" className="Pricing--select"  ref={node}>
            <div className="Pricing--select--div px-2">
              <span className="Pricing--select--cursor" onClick={(e)=>setOpen(current => !current)}>{unit} <IoIosArrowDown className="fs-4"/></span>
              <ul className="list-unstyled Pricing--select--div--ul py-2"
              style={{
                display: open ? 'block'  : 'none',
              }}
              >
              <li className="Pricing--select--option px-2" onClick={GBP} 
              style={{
                fontWeight: priceclick ? 500  : 400,
                background: priceclick ? 'var(--light)': '',
                color: priceclick ? 'var(--dark)': ''
              }}
              >GBP</li>
              <li className="Pricing--select--option px-2" onClick={EUR} 
              style={{
                fontWeight: eurclick ? 500  : 400,
                background: eurclick ? 'var(--light)': '',
                color: eurclick ? 'var(--dark)': ''
              }}
              >EUR</li>
              <li className="Pricing--select--option px-2" onClick={USD}
              style={{
                fontWeight: usdclick ? 500  : 400,
                background: usdclick ? 'var(--light)': '',
                color: usdclick ? 'var(--dark)': ''
              }}
              >USD</li>
              </ul>
            </div>
          </div>
          {/* card1 */}
            <div className="Col me-sm-3 Col1">
              <div className="Pricing__card px-4 py-4">
                <h4 className="Pricing__card__heading">
                  Basic
                </h4>
                <p className="Pricing__card__detail">
                Built for employers with up to 2 open positions per month.
                </p>
                <h4 className="Pricing__card--h4"><span className="Pricing__card__price">{price[0]}</span><small className="Pricing__card__price--small">{month}</small></h4>
                <p className="Pricing__card__detail--small mt-1">*Additional background check charged at {corruncy} per hire</p>
                <button className='Pricing__card--button'><a href="mailto:support@rndmatch.com" className="Pricing__card--button--Link text-decoration-none">Buy Now</a></button>
              </div>
              <div className="Pricing__include ps-4 mt-4 mb-5">
                <h6 className="Pricing__include__heading mb-3">
                  Includes:
                </h6>
                {card1List.map((item)=>(
                  <div className="Pricing__include__list mb-2 d-flex" key={item.id}>
                  <HiOutlineCheck className="Pricing__include__list__icon me-2 fs-4"/>
                  <span className="Pricing__include__list__text">{item.text}</span>
                </div>
                ))}
              </div>
            </div>

          {/* card2 */}
            <div className="Col me-sm-3 Col2">
              <div className="Pricing__card px-4 py-4">
                <h4 className="Pricing__card__heading">
                  Plus
                </h4>
                <p className="Pricing__card__detail">
                Built for employers with up to 5 open positions per month.
                </p>
                <h4 className="Pricing__card--h4"><span className="Pricing__card__price">{price[1]}</span><small className="Pricing__card__price--small">{month}</small></h4>
                <p className="Pricing__card__detail--small mt-1">*Additional background check charged at {corruncy} per hire</p>
                <button className='Pricing__card--button'><a href="mailto:support@rndmatch.com" className="Pricing__card--button--Link text-decoration-none">Buy Now</a></button>
              </div>
              <div className="Pricing__include ps-4 mt-4">
                <h6 className="Pricing__include__heading mb-3">
                  Includes:
                </h6>
                {card2List.map((item)=>(
                  <div className="Pricing__include__list mb-2 d-flex" key={item.id}>
                  <HiOutlineCheck className="Pricing__include__list__icon me-2 fs-4"/>
                  <span className="Pricing__include__list__text">{item.text}</span>
                </div>
                ))}
              </div>
            </div>

            {/* card3 */}
            <div className="Col me-sm-3 Col3">
              <div className="Pricing__card px-4 py-4">
                <h4 className="Pricing__card__heading">
                  Premium
                </h4>
                <p className="Pricing__card__detail">
                Built for employers with up to 10 open positions per month.
                </p>
                <h4 className="Pricing__card--h4"><span className="Pricing__card__price">{price[2]}</span><small className="Pricing__card__price--small">{month}</small></h4>
                <p className="Pricing__card__detail--small mt-1">*Additional background check charged at {corruncy} per hire</p>
                <button className='Pricing__card--button'><a href="mailto:support@rndmatch.com" className="Pricing__card--button--Link text-decoration-none">Buy Now</a></button>
              </div>
              <div className="Pricing__include ps-4 mt-4 mb-5">
                <h6 className="Pricing__include__heading mb-3">
                  Includes:
                </h6>
                {card3List.map((item)=>(
                  <div className="Pricing__include__list mb-2 d-flex" key={item.id}>
                  <HiOutlineCheck className="Pricing__include__list__icon me-2 fs-4"/>
                  <span className="Pricing__include__list__text">{item.text}</span>
                </div>
                ))}
              </div>
            </div>
            
            {/* card4 */}
            <div className="Col me-xl-0 me-sm-3 Col4">
              <div className="Pricing__card px-4 py-4">
                <h4 className="Pricing__card__heading">
                  Unlimited
                </h4>
                <p className="Pricing__card__detail">
                Built for employers with up to 10 open positions per month.
                </p>
                <h4 className="Pricing__card--h4"><span className="Pricing__card__price">Custom</span><small className="Pricing__card__price--small">{month}</small></h4>
                <p className="Pricing__card__detail--small mt-1">*Additional background check charged at {corruncy} per hire</p>
                <button className='Pricing__card--button'><a href="mailto:support@rndmatch.com" className="Pricing__card--button--Link text-decoration-none">Buy Now</a></button>
              </div>
              <div className="Pricing__include ps-4 mt-4 mb-5">
                <h6 className="Pricing__include__heading mb-3">
                  Includes:
                </h6>
                {card4List.map((item)=>(
                  <div className="Pricing__include__list mb-2 d-flex" key={item.id}>
                  <HiOutlineCheck className="Pricing__include__list__icon me-2 fs-4"/>
                  <span className="Pricing__include__list__text">{item.text}</span>
                </div>
                ))}
              </div>
            </div>

            {/* card5 */}
            <div className="Col me-sm-3 Col1">
              <div className="Pricing__card px-4 py-4">
                <h4 className="Pricing__card__heading">
                Pay per hire
                </h4>
                <p className="Pricing__card__detail">
                Built for employers interested in pay as you go.
                </p>
                <h4 className="Pricing__card--h4"><span className="Pricing__card__price">{talent}</span><small className="Pricing__card__price--small">/hire</small></h4>
                <p className="Pricing__card__detail--small mt-1">*Additional background check charged at {corruncy} per hire</p>
                <button className='Pricing__card--button'><a href="mailto:support@rndmatch.com" className="Pricing__card--button--Link text-decoration-none">Buy Now</a></button>
              </div>
              <div className="Pricing__include ps-4 mt-4 mb-5">
                <h6 className="Pricing__include__heading mb-3">
                  Includes:
                </h6>
                {card5List.map((item)=>(
                  <div className="Pricing__include__list mb-2 d-flex" key={item.id}>
                  <HiOutlineCheck className="Pricing__include__list__icon me-2 fs-4"/>
                  <span className="Pricing__include__list__text">{item.text}</span>
                </div>
                ))}
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
