import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

function Content() {
  return (
    <div className='Content py-5'>
        <div className="container-md py-5 px-sm-5 px-4 d-flex justify-content-center">
            <div className="Content__text">
                <h1 className="Content__text__heading mt-5">
                Access to the world’s best R&D <span className='Content__text__heading--green--break'>talents – </span>
                <span className='Content__text__heading--green'> match more candidates with AI, <span className='Content__text__heading--green--break'> ML & NLP</span></span>
                </h1>
                <p className='Content__text__detail'>Join many R&D businesses using our automated sourcing and matching product powered by artificial intelligence, machine learning & semantic technology</p>
                <button className='Content__text--button'><Link to={`/employer`} className="Content__text--button--Link text-decoration-none">Find candidates now</Link></button>
            </div>
        </div>
    </div>
  )
}

export default Content