import React from 'react'
import './style.css'
import logo from '../../Images/logo.png'
import { Link } from 'react-router-dom'
import { useState, useRef, useEffect} from 'react'
import {CgMenuRightAlt} from 'react-icons/cg'

const List=[
    {
        id: 1,
        ref: `/employers`,
        text: 'Employers'
    },
    {
        id: 2,
        ref: `/candidates`,
        text: 'Candidates'
    },
    {
        id: 3,
        ref: `/solution`,
        text: 'Solution'
    },
    {
        id: 4,
        ref: `/pricing`,
        text: 'Pricing'
    },
    {
        id: 5,
        ref: `/about`,
        text: 'About'
    },
    {
        id: 6,
        ref: `/contact`,
        text: 'Contact'
    }
]


function Navbar(prop) {
    const [scroll, setScroll] = useState(false);
    const [popup, setPopup] = useState(false);

    const data = useRef();
    const onclickOutside = (e) => {
      if(data.current.contains(e.target)) {
        // inside click
        console.log('clicked inside')
        return;
      } 
      // outside click
        console.log('clicked outside scope')
        setPopup(false);
    }
    useEffect(() => {
      document.addEventListener('mousedown', onclickOutside);
  
      // clean up function before running new effect
      return () => {
          document.removeEventListener('mousedown', onclickOutside);
      }
    }, [popup])

    const changeBackground = () => {
        console.log(window.scrollY)
        if (window.scrollY >= 10) {
          setScroll(true)
        } else {
          setScroll(false)
        }
      }
    
      useEffect(() => {
        changeBackground()
        window.addEventListener("scroll", changeBackground)
      })
  return (
    <div className={`Navbar pe-md-0 pe-sm-4 pe-1 ps-md-0 ps-sm-5 ps-3 ${prop.class}`}
    style=
    {{
        background: scroll ? prop.Scolor : prop.color, 
        boxShadow: scroll? '0px 15px 43px rgba(160,168,189,0.3) ' : '0px 0px 0px #3333334d',
        padding: scroll? '5px 0px': '5px 0px',
    }}
    >
        <div className="container-md py-3 d-flex justify-content-between align-content-center">
            <Link to={'/'} className="Navbar__brand navbar-brand">
                <img src={logo} alt="Logo" className='Navbar__brand__img'/>
            </Link>
            <div className="Navbar__right d-xl-flex d-none align-items-center">
            <ul className="Navbar__right__nav list-unstyled d-lg-flex align-content-center m-0 d-none">
               {List.map((item)=>(
                 <li className="Navbar__right__nav__item mx-3 d-flex align-items-center" key={item.id}>
                    <Link to={item.ref} className="Navbar__right__nav__item__link text-decoration-none">
                        {item.text}
                    </Link>
                </li>
               ))}
            </ul>
            <Link to={`/login`} className='text-decoration-none pt-1 px-4 Navbar__right__btn mx-4'>
                Login
            </Link>
            <div className='Navbar__right__popup px-3' onClick={(e)=>setPopup(current => !current)} ref={data}>
                Get Started
                <ul className="Navbar__right__popup__menu list-unstyled" 
                style={{
                    transform: popup ? 'scale(1)' : 'scale(0)', 
                }}
                >
                    <li className="Navbar__right__popup__menu__item pb-2 pt-3 text-start">
                        <Link to={`/employer`} className="text-decoration-none Navbar__right__popup__menu__item__link px-4">Employer</Link>
                    </li>
                    <li className="Navbar__right__popup__menu__item pt-2 pb-3 text-start">
                        <Link to={`/candidate_step1`} className="text-decoration-none Navbar__right__popup__menu__item__link px-4">Candidate </Link>
                    </li>
                </ul>
            </div>
            </div>
            <CgMenuRightAlt className='burger-icon fs-1 d-xl-none d-block' data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample"/>
        </div>
    </div>
  )
}

export default Navbar