import React from 'react'
import { Link } from 'react-router-dom'
import './Policy/style.css'

function Terms() {
  return (
    <div className='Policy d-flex justify-content-center px-4'>
            <div className="Policy__container pb-5 pt-4">
                <h6 className="Policy__container--h6 text-uppercase">
                WHO WE ARE AND HOW TO CONTACT US
                </h6>
                <p className='Policy__container__para'>
                rndmatch.com is a site operated by rndmatchLimited ("We").
                </p>
                <p className='Policy__container__para'>We are a limited company registered in England and Wales under company number 13901214 and have our registered office at 5 Briskman Way, Aylesbury HP21 8FP, United Kingdom.</p>
                <p className='Policy__container__para'>To contact us, please email support@rndmatch.com.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                BY USING OUR SITE YOU ACCEPT THESE TERMS
                </h6>
                <p className='Policy__container__para'>By using our site, you accept these terms of use and that you agree to comply with them. If you do not agree to these terms, you must not use our site.</p>
                <p className='Policy__container__para'>Please read and review these terms carefully and make sure that you understand them before using the site.</p>
                <p className='Policy__container__para'>We recommend that you print a copy of these terms for your future reference.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                ACCEPTANCE OF THESE TERMS
                </h6>
                <p className='Policy__container__para'>These terms of use refer to the additional terms set out below. These also apply to your use of our site.</p>
                <ul className='Policy__container__list'>
                    <li className="Policy__container__list__item mb-2">Our Privacy Policy, which is available <Link to={'/privacy'} className='Policy__container__para--Link'>here</Link>. This sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using our site, you consent to such processing and you warrant that all data provided by you is accurate.</li>
                    <li className="Policy__container__list__item mb-2">Our Acceptable Use Policy, which is available <Link to={'/acceptable-use'} className='Policy__container__para--Link'>here</Link>. This sets out the permitted uses and prohibited uses of our site. When using our site, you must comply with this Acceptable Use Policy.</li>
                    <li className="Policy__container__list__item mb-2">Our Cookie Policy, which is available <Link to={'/cookies'} className='Policy__container__para--Link'>here</Link>. This sets out information about the cookies on our site.</li>
                </ul>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                WE MAY MAKE CHANGES TO THESE TERMS
                </h6>
                <p className='Policy__container__para'>We amend these terms from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time.</p>
                <p className='Policy__container__para'>These terms were most recently updated on 30 December 2022.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                WE MAY MAKE CHANGES TO OUR SITE
                </h6>
                <p className='Policy__container__para'>We may update and change our site from time to time to reflect changes to our product, our users' needs and our business priorities. We will try to give you reasonable notice of any major changes.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                WE MAY SUSPEND OR WITHDRAW OUR SITE
                </h6>
                <p className='Policy__container__para'>We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our site for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</p>
                <p className='Policy__container__para'>You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                YOU AGREE TO PROVIDE US WTH INFORMATION
                </h6>
                <p className='Policy__container__para'>If you are a software engineer, by using our site you agree to provide us with such information as we may reasonably request, including (without limitation) as to your identity, and information relating to your experience, qualifications, training and authorisations as are necessary.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                KEEP YOUR ACCOUNT DETAILS SAFE
                </h6>
                <p className='Policy__container__para'>If you choose, or you are provided with, a user identification log-in, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.</p>
                <p className='Policy__container__para'>We have the right to disable any user identification log-in or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms.</p>
                <p className='Policy__container__para'>If you know or suspect that anyone other than you knows your user identification log-in or password, you must promptly notify us at support@rndmatch.com.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                PERMITTED USES OF THE MATERIAL ON OUR SITE
                </h6>
                <p className='Policy__container__para'>The intellectual property rights in our site and the material published on it are protected by copyright laws and treaties around the world. All such rights are reserved.</p>
                <p className='Policy__container__para'>You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site.</p>
                <p className='Policy__container__para'>You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us or our licensors.</p>
                <p className='Policy__container__para'>If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                DO NOT RELY ON INFORMATION ON THIS SITE
                </h6>
                <p className='Policy__container__para'>The content on our site is not intended to amount to advice on which you should rely. We make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK TO
                </h6>
                <p className='Policy__container__para'>Where our site contains links to other sites and resources provided by third parties, these links are provided for your ease of reference and information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.</p>
                <p className='Policy__container__para'>We have no control over the contents of those sites or resources.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                WE DO NOT APPROVE USER-GENERATED CONTENT
                </h6>
                <p className='Policy__container__para'>This site includes information and materials uploaded by other users of the site, including to bulletin boards and chat functions. This information and these materials have not been verified or approved by us. The views expressed by other users on our site do not represent our views or values.</p>
                <p className='Policy__container__para'>Please contact us by e-mailing support@rndmatch.comin the event that you wish to complain about information and materials uploaded by other users. No one other than a party to this agreement shall have any right to enforce any of its terms.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU
                </h6>
                <p className='Policy__container__para'>A person who is not a party to these terms and conditions shall not have any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this agreement.</p>
                <h6 className="Policy__container--h6 pt-3">
                Whether you are a consumer or a business user:
                </h6>
                <ul className='Policy__container__list'>
                    <li className="Policy__container__list__item mb-2">We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.</li>
                </ul>
                <h6 className="Policy__container--h6 pt-3">
                If you are a business user:
                </h6>
                <ul className='Policy__container__list'>
                    <li className="Policy__container__list__item mb-2">We exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it.</li>
                    <li className="Policy__container__list__item mb-2">We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
                    <ul className='mt-2'>
                    <li className="Policy__container__list__item mb-1">use of, or inability to use, our site; or</li>
                    <li className="Policy__container__list__item">use of or reliance on any content displayed on our site.</li>
                    </ul>
                    </li>
                    <li className="Policy__container__list__item mb-2">In particular, we will not be liable for:
                    <ul className='mt-2'>
                    <li className="Policy__container__list__item mb-1">business interruption;</li>
                    <li className="Policy__container__list__item mb-1">loss of anticipated savings;</li>
                    <li className="Policy__container__list__item mb-1">any indirect or consequential loss or damage.</li>
                    </ul>
                    </li>
                </ul>
                <h6 className="Policy__container--h6 pt-3">
                If you are a consumer user:
                </h6>
                <p className='Policy__container__para'>We have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>
                <h6 className="Policy__container--h6 pt-3">
                We have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.
                </h6>
                <p className='Policy__container__para'>Whenever you upload content to our site, or to make contact with other users of our site, you must comply with the terms and conditions set out in this terms and conditions policy and the content standards set out in our Acceptable Use Policy (this is available <Link to={'/acceptable-use'} className='Policy__container__para--Link'>here</Link>).</p>
                <p className='Policy__container__para'>You warrant that any such contribution does comply with those standards, and you will be liable to us and indemnify us for any breach of that warranty. This means you will be responsible for any loss or damage we suffer as a result of your breach of warranty.</p>
                <p className='Policy__container__para'>Any content you upload to our site will be considered non-confidential and non-proprietary. You retain all of your ownership rights in your content, but you are required to grant us and other users of our site a limited licence to use, store and copy that content and to distribute and make it available to third parties. The rights you license to us are described in the paragraph below, under “Rights you are giving us to use material you upload.”</p>
                <p className='Policy__container__para'>We have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to our site constitutes a violation of their intellectual property rights, or of their right to privacy.</p>
                <p className='Policy__container__para'>We have the right to remove any posting you make on our site if, in our reasonable opinion, your post does not comply with the content standards set out in our Acceptable Use Policy, which is available <Link to={'/acceptable-use'} className='Policy__container__para--Link'>here</Link>.</p>
                <p className='Policy__container__para'>You are solely responsible for securing and backing up your content.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                RIGHTS YOU ARE GIVING US TO USE MATERIAL YOU UPLOAD
                </h6>
                <p className='Policy__container__para'>When you upload or post content to our site, you grant us, other users of the site, and third parties such as social media sites (including but not limited to Facebook, Twitter, and LinkedIn) a perpetual, worldwide, non-exclusive, royalty-free, transferable licence to use, reproduce, distribute, prepare derivative works of, display, and perform that user-generated content in connection with the service of provided by the site and across different media and in connection with promoting the site.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                WE ARE NOT RESPONSIBLE FOR VIRUSES AND YOU MUST NOT INTRODUCE THEM
                </h6>
                <p className='Policy__container__para'>We do not guarantee that our site will be secure or free from bugs or viruses</p>
                <p className='Policy__container__para'>You are responsible for configuring your information technology, computer programmes and platform to access our site.</p>
                <p className='Policy__container__para'>You should use your own virus protection software. You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                RULES ABOUT LINKING TO OUR SITE
                </h6>
                <p className='Policy__container__para'>You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>
                <p className='Policy__container__para'>You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p>
                <p className='Policy__container__para'>You must not establish a link to our site in any website that is not owned by you.</p>
                <p className='Policy__container__para'>Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</p>
                <p className='Policy__container__para'>We reserve the right to withdraw linking permission without notice.</p>
                <p className='Policy__container__para'>The website in which you are linking must comply in all respects with the content standards set out in our Acceptable Use Policy. Our acceptable use policy is <Link to={'/acceptable-use'} className='Policy__container__para--Link'>here</Link>.</p>
                <p className='Policy__container__para'>If you wish to link to or make any use of content on our site other than that set out above, please contact support@rndmatch.com.</p>
                <h6 className="Policy__container--h6 text-uppercase pt-3">
                WHICH COUNTRY'S LAWS APPLY TO ANY DISPUTES?
                </h6>
                <p className='Policy__container__para'>If you are a consumer, please note that these terms of use, their subject matter and their formation, are governed by English law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction except that if you are a resident of Northern Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may also bring proceedings in Scotland.</p>
               <p className='Policy__container__para'>If you are a business, these terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.</p>
               <h6 className="Policy__container--h6 text-uppercase pt-3">
               FORCE MAJEURE EVENT
                </h6>
               <p className='Policy__container__para'>Neither you or us shall be in breach of these terms and conditions nor liable for any delay in performing or failure to perform any of its obligations under these terms and conditions if such failure or delay results from events, circumstances or causes beyond its reasonable control, including without limitation, acts of God , flood, drought, earthquake or other natural disaster, epidemic or pandemic, terrorist attack, civil war, civil commotion or riots, war , threat of or preparation for war, armed conflict, imposition of sanctions, embargoes or breaking off of diplomatic relations, nuclear, chemical or biological contamination, any law or any action taken by a government or public authority (including without limitation imposing an export or import restriction, quota or prohibition, or failing to grant a necessary licence or consent, collapse of buildings, fire, explosion or accident, and interruption or failure of utility service.</p>
        </div>        
    </div>
  )
}

export default Terms