import React from 'react'
import './style.css'
import Ehero from '../..//Images/SignIn/sign-in hero.png';
import Top from '../../Images/SignIn/sign-in top.png'
import Bottom from '../../Images/SignIn/sign-in bottom.png'
import Select from 'react-select'
import { Link } from 'react-router-dom'

const options = [
    { value: 'cashier', label: 'Cashier' },
    { value: 'manager', label: 'Manager' },
    { value: 'IT Expert', label: 'IT Expert' }
  ]
  const MultiOptions = [
    { value: 'San Francisco', label: 'San Francisco' },
    { value: 'CA', label: 'CA' },
    { value: 'USA1', label: 'USA' },
    { value: 'UAE2', label: 'UAE' },
    { value: 'UAE3', label: 'UAE' },
    { value: 'UAE4', label: 'UAE' },
    { value: 'UAE5', label: 'UAE' },
    { value: 'Paris', label: 'Paris' },
  ]  
  const customStyles = {
    control: (base, state) => ({
      ...base,
      '&:hover': { borderColor: 'lightgray' }, // border style on hover
        border: '1px solid lightgray', // default border color
        borderRadius: '10px',
        boxShadow: 'none', // no box-shadow
        color: state.isSelected ? 'green' : 'green',
        backgroundColor: state.isSelected ? 'green' : '',
    }),
    option: (base, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...base,
        backgroundColor: isFocused ? "#B7C93B" : "white",
        background: isSelected ? "#B7C93B" : "white",
        '&:hover': { backgroundColor: isSelected ? "#B7C93B": '#B7C93B', color: isSelected ? "#fff": "#fff", opacity: isSelected? '1': '0.7' },
        Transition: "all 0.5s ease"
      };
},
    menuList: (base) => ({
        ...base,
        '&:hover': { borderColor: 'lightgray' }
      }),
      multiValue: (styles, {data}) =>{
        return {
          ...styles,
          backgroundColor: "#B7C93B",
          color: '#fff',
          fontFamily: `'Red Hat Text', sans-serif`
        }
      },
      multiValueLabel: (styles, {data}) =>{
        return {
          ...styles,
          color: '#fff',
          // fontWeight: '500'
        }
      },
      multiValueRemove: (styles, {data}) =>{
        return {
          ...styles,
          color: '#fff',
          ':hover':{
            color: "#fff",
            backgroundColor: "#B7C93B",
          }
        }
      }
  }; 


function Signup() {
  return (
    <div className='signup mt-5 px-4'>
        <div className="container-lg py-5 px-md-0 px-sm-5 px-3">
            <div className="row mt-4">
                <div className="col-md-6 p-0">
                <div className="Login__form__left w-100 h-100 d-flex py-5 px-4 text-center flex-column justify-content-center align-items-center">
                        <img src={Top} alt="" className='Login__form__left__top '/>
                        <img src={Ehero} alt="" className='signup__left--img'/>
                        <img src={Bottom} alt="" className='Login__form__left__bottom'/>
                    </div>
                </div>
                <div className="col-md-6 signup__form">
                    <form action="/" className=' py-5 px-sm-5 px-2'>
                        <div className="signup__form__head d-flex flex-column align-items-center mb-2 text-center">
                            <h2 className="signup__form__head--h2 mx-auto"> Find a talent </h2>
                            <p className='signup__form__head--p'>We have thousands of qualified candidates waiting for you !</p>
                        </div>
                        <small className='signup__form--small-dark ms-2'>Which role are you hiring for?*</small>
                        <Select options={options} styles={customStyles} placeholder="Select a role..." className='mb-3 signup__form--select'/>
                        <small className='signup__form--small-dark ms-2'>Where are your hiring?(up to 8)*</small>
                        <Select isMulti options={MultiOptions} styles={customStyles} placeholder="e.g Oxford, UK" className=' signup__form--select'/>
                        <small className='signup__form--small-light ms-2'>Try remote*</small>
                        <div className="row mt-3">
                          <div className="col-sm-6">
                            <label htmlFor="name"><small className='signup__form--small-light ms-2'>First Name*</small></label>
                            <input type="text" className='signup__form--input w-100' id='name'/>
                          </div>
                          <div className="col-sm-6 mt-sm-0 mt-3">
                            <label htmlFor="LastName"><small className='signup__form--small-light ms-2'>Last Name*</small></label>
                            <input type="text" className='signup__form--input w-100' id='LastName'/>
                          </div>
                          <div className="col-12 mt-3">
                          <label htmlFor="company"><small className='signup__form--small-light ms-2'>Company*</small></label>
                            <input type="text" className='signup__form--input w-100' id='company'/>
                          </div>
                          <div className="col-12 mt-3">
                          <label htmlFor="email"><small className='signup__form--small-light ms-2'>Work Email*</small></label>
                            <input type="email" className='signup__form--input w-100' id='email'/>
                          </div>
                        </div>
                        <div className='d-flex align-items-start mt-2 ms-2'>
                          <input type="checkbox" name="terms" id="terms" className='mt-2 me-2 signup__form--checkbox' />
                          <p className='signup__form--p' id='terms'>By signing up you agree to rndmatch’s <Link to={'/terms'} className='signup__form--p--Link'>Terms of Service</Link> and <Link to={'/privacy'} className='signup__form--p--Link'>Privacy Policy</Link>, which outline your rights and obligations with respect to your use of the Service and processing of your data.</p>
                        </div>
                        <div className='d-flex align-items-start mt-2 ms-2'>
                          <input type="checkbox" name="terms" id="terms" className='mt-2 me-2 signup__form--checkbox' />
                          <p className='signup__form--p' id='terms'>You agree to receive subsequent email and third-party communications, which you may opt out of, or unsubscribe from, at any time. <br /> rndmatch is committed to protecting your data privacy. Permissions are part of our continuing compliance efforts. <br /> We will provide reasonable accommodations with regard to use of this site. <br /> If you require an accommodation while using our site, please contact support@ndmatch.com to request assistance.</p>
                        </div>
                          <div className='d-flex justify-content-center'>
                          <button type='submit' className='signup__form--button my-3 w-sm-50 w-75 py-2'>See candidates now</button>
                          </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Signup