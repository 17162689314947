import React from 'react'
import './style.css'
import Job1 from '../../../Images/Job 1.png'
import Job2 from '../../../Images/Job 2.png'
import Job3 from '../../../Images/Job 3.png'
import video from '../../../Videos/Job.mp4'
import { Link } from 'react-router-dom'

const job= [
    {
        id: 1,
        img: Job1,
        heading: "Employers apply to you",
        para: "You are more than your CV or cover letter. Sit back and let employers send you interview requests."
    },
    {
        id: 2,
        img: Job2,
        heading: "No commitments, 100% free",
        para: "rndmatch is 100% free and confidential. Choose which interview to accept, completely risk-free."
    },
    {
        id: 3,
        img: Job3,
        heading: "Transparent offers",
        para: "Compare exclusive interview requests side by side with salary details up front."
    }
]

function Job() {
  return (
    <div className='Job pt-3 pb-5 px-md-0 px-3'>
        <div className="container-md mb-4">
            <div className="row Job--row p-0 ">
                <div className="col-lg-6 order-lg-1 order-2 py-xxl-4">
                    <div className="Job__content pt-4 pb-4">
                        <h3 className="Job__content__heading my-lg-4 my-3">
                        Find the right job at the right pay
                        </h3>
                        {job.map((item)=>(
                            <div className="Job__content__row d-flex mb-3" key={item.id}>
                            <img src={item.img} alt="Job1" className='Job__content__row__img mt-2'/>
                            <div className="Job__content__row__text ms-3 ">
                                <h5 className="Job__content__row__text__heading">
                                {item.heading}
                                </h5>
                                <p className="Job__content__row__text__para">
                               {item.para}
                                </p>
                            </div>
                        </div>
                        ))}
                        <button className='Job__content--button mt-md-3 mb-lg-3 mb-3'><Link to={`/candidate_step1`} className='text-decoration-none Job__content--button__link'>Find a job now</Link></button>
                    </div>
                </div>
                <div className="col-lg-6 p-0 Job--col m-0 order-lg-2 order-1 d-lg-block d-none">
                        <video loop autoPlay muted className='Job--video'>
                            <source src={video} type="video/mp4" />
                        </video>
                </div>
                <div className="col-lg-6 p-0 Job--col order-lg-2 order-1 m-0 d-lg-none d-flex justify-content-center">
                        <video loop autoPlay muted className='Job--video2'>
                            <source src={video} type="video/mp4" />
                        </video>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Job