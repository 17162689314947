import React from 'react';
import './style.css'
import Slider from "react-slick";
import carousel2 from '../../../Images/Carousel/carousel 2.png'
import carousel3 from '../../../Images/Carousel/carousel 3.png'
import carousel4 from '../../../Images/Carousel/carousel 4.png'
import carousel5 from '../../../Images/Carousel/carousel 5.png'
import carousel6 from '../../../Images/Carousel/carousel 6.png'
import carousel7 from '../../../Images/Carousel/carousel 7.png'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';

const settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000
};

function Hero() {
  return (
    <div className='Hero'>
        <div className="container-md py-5 my-5">
            <div className="row pt-xl-0 pt-4">
            <div className="col-lg-7 col-12 p-0 order-lg-1 order-2">                    
                <div className="Hero__content h-100 d-flex flex-column justify-content-center ps-lg-2 ps-4 pe-md-1 pe-2 pb-4">
                        <h2 className='Hero__content__heading d-lg-block d-none'>Talent intelligence platform for R&D</h2> 
                        <p className='Hero__content__para mb-md-2 mb-0'>Intelligent sourcing and hiring of top R&D talent such as postdocs, research scientists, proteomicists, programme managers, bioinformaticians, data scientists, and many more.</p>
                        <button className='Hero__content--button my-3'><Link to={`/employer`} className='text-decoration-none Hero__content--button__link'>Hire a talent now</Link></button>
                </div>                   
            </div>  
            <div className="col-lg-5 col-12 px-lg-0 px-2 order-lg-2 order-1 mb-lg-5 mb-4">
            <h2 className='Hero__content__heading d-lg-none d-inline-block ps-2 pb-3 text-center'>Talent intelligence platform for R&D</h2> 
            <Slider {...settings} className="">
          <div className="banner-image d-flex justify-content-xl-start justify-content-lg-end justify-content-center">
            <img src={carousel4} className=" Hero__content--img" alt=''/>
          </div>
          <div className="banner-image d-flex justify-content-xl-start justify-content-lg-end justify-content-center">
            <img src={carousel3} className=" Hero__content--img" alt=''/>
          </div>
          <div className="banner-image d-flex justify-content-xl-start justify-content-lg-end justify-content-center">
            <img src={carousel2} className=" Hero__content--img" alt=''/>
          </div>
          <div className="banner-image d-flex justify-content-xl-start justify-content-lg-end justify-content-center">
            <img src={carousel5} className=" Hero__content--img" alt=''/>
          </div>
          <div className="banner-image d-flex justify-content-xl-start justify-content-lg-end justify-content-center">
            <img src={carousel6} className=" Hero__content--img" alt=''/>
          </div>
          <div className="banner-image d-flex justify-content-xl-start justify-content-lg-end justify-content-center">
            <img src={carousel7} className=" Hero__content--img" alt=''/>
          </div>
        </Slider>
            </div>   
            </div>
        </div>
    </div>
  )
}

export default Hero