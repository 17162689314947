import React from 'react'
import './style.css'
import aboutVideo from '../../../Videos/About.mp4';

function AVideo() {
  return (
    <div className="Avideo pb-md-5 pt-sm-4">
          <div className="container-md d-flex flex-column align-items-center py-5 px-md-0 px-sm-5 px-4">
            <div className="Avideo__content text-center">
            <h1 className="Avideo__content__heading">
            We believe...
            </h1>
            <span className='Avideo__content__para'>We believe it’s not about job board advertising or reviewing CVs and cover letters, it’s about connecting ambitious teams to the right candidates; </span> <br />
            <span className='Avideo__content__para'>it’s about matching perfect candidates to jobs that best fit their skills and preferences.</span>
            </div>
            <video loop autoPlay muted className='Avideo--video mt-sm-5 mt-4'>
                <source src={aboutVideo} type="video/mp4"/>
            </video>
          </div>
        </div>
  )
}

export default AVideo